// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*========================================================================*/
/*=================== MFE Configuration Chip ========================*/
/*========================================================================*/
@import 'mixins/mixins';


.aw-epChip-label, .aw-epChip-data{
    color: $brand_secondary_content_text;
    cursor: default;
}
.aw-epChip-container{
    background-color: $brand_table_header_background;
}
