// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*========================== EP Structure Configuration =========================*/

.aw-epStructureConfiguration-hintButton {
    width: rem-calc(12);
    margin-top: $margin_normal;
    margin-left: $margin_small;
}

.aw-epStructureConfiguration-topBottomMarginLarge {
    margin-top: $margin_large;
    margin-bottom: $margin_large;
}

.aw-epStructureConfiguration-checkBoxHeight {
    min-height: rem-calc(30);

}
