// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-epFunctionalPlan-classification .aw-clspanel-viewClassification .aw-widgets-cellListWidget .aw-widgets-cellListItem {
    width: calc(100% - 16px);
}

.aw-epFunctionalPlan-classification .aw-widgets-cellListWidget .aw-widgets-cellListItem {
    white-space: initial;
}
