/* Copyright 2019 Siemens Product Lifecycle Management Software Inc. */
.aw-classificationpanelsjs-searchVisualNavigationContent .aw-widgets-cellListWidget .aw-widgets-cellListItem {
    -moz-box-shadow: $drop_shadow_small;
    -webkit-box-shadow: $drop_shadow_small;
    box-shadow: $drop_shadow_small;
}

.aw-classificationpanelsjs-searchVisualNavigationContent .aw-widgets-cellListWidget .aw-widgets-cellListItem:hover {
    background: $brand_primary_content_background;
    -moz-box-shadow: $drop_shadow_x_large;
    -webkit-box-shadow: $drop_shadow_x_large;
    box-shadow: $drop_shadow_x_large;
}

.sw-vnc-container{
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    background-color: $Siemens_Snow;
}

.sw-vnc-container:hover{
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.3);
}


.aw-cls-suggested-vnc .aw-clspanel-thumbnailRibbonContainer .aw-clspanel-thumbnailRibbonContent {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.aw-cls-widgets-cellListItemSelected {
    background-color: $Siemens_Snow;
    fill: $Natural_Blue_8;
}

.aw-clspanel-block,
.aw-clspanel-childHover,
.aw-clspanel-cardinalParent .aw-splm-tableContainer {
    border-color: $brand_primary_content_background;
}

.aw-clspanel-parentHover {
    border-color: $brand_secondary_border;
}

.aw-clspanel-childHover {
    border-color: $brand_secondary_link;
}

.aw-clspanel-pasteBreadcrumb{
    background-color: $cell_list_item_hover;
    color: $brand_selection_text_hover;
    border-color: $cell_list_item_border_color;
    border-bottom-color: $cell_item_main_border_bottom_color;
}

.aw-clspanel-showMinMax:focus {
    .keyboard & {
        outline-color: $brand_main_focus_color;
    }
}
