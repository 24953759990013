/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-epSearchObject-listBoxWidth {
    width: 100%;
}

.aw-layout-popup .aw-epSearchObject-listBoxWidth .aw-widgets-cellListWidget .aw-widgets-cellListItem {
    cursor: default;
}

.aw-epStructureSearch-body {
    padding: 4px;
    margin-bottom: 2px;
    overflow: hidden;
    .aw-uiwidgets-searchBoxContainer{
        margin-bottom: 0;
    }
}

.aw-epStructureSearch-boxstyle {
    textarea, input, input[type="text"], input[type="password"] {
        border-style: none;
        padding: rem-calc(5);
    }
    textarea:focus, textarea:hover, input:focus, input:hover, input[type="text"]:focus, input[type="text"]:hover, input[type="password"]:focus, input[type="password"]:hover {
        padding: rem-calc(5);
    }
}

.aw-epStructureSearchResults{
    height:rem-calc(100);
    .aw-widgets-cellListItemContainer{
        align-items: center;
    }
    .aw-widgets-cellListItem.sw-aria-border{
        padding-bottom: rem-calc(4);
        padding-top: rem-calc(4);
    }
}

.aw-epStructureSearch-resultItemTitle {
    display: inherit;
    padding: 9px 0 4px 5px;
    height: rem-calc(34);
}

.aw-epStructureSearch-resultItemIcon {
    height: rem-calc(24);
    padding-right: 6px;
    vertical-align: bottom;
}

.aw-epStructureSearch-resultItemText {
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.aw-epStructureSearch-list {
    li {
        padding: 2px 0 2px 0;
        border-width: 0;
        width: 100%;
        .aw-widgets-cellListItemCell {
            white-space: nowrap;
            display: contents;
        }
    }

    .aw-widgets-modelObjectList {
        margin-bottom: 0;
    }
}

.aw-mfe-epSearchObjectScrollPanel {
    margin-top : $margin_large;
}
