// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*========================================================================*/
/*============================ EWI Subpage Content =======================*/
/*========================================================================*/

.aw-ewi-subpageContent{
    .aw-sidenav-push.aw-sidenav-rightBackground {
        box-shadow: $left_shadow_2x_large;
    }
}
