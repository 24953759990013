// Copyright 2020 Siemens Product Lifecycle Management Software Inc.

.aw-activecollab-shadow .aw-widgets-cellListItem {
    border-color: $PL_Black_23;
    box-shadow: $drop_shadow_normal;
    -moz-box-shadow: $drop_shadow_small;
    -webkit-box-shadow: $drop_shadow_small;
    background-color: $brand_primary_content_background;
    &:hover {
        background-color: $brand_primary_content_background;
        -moz-box-shadow: $drop_shadow_x_large;
        -webkit-box-shadow: $drop_shadow_x_large;
        box-shadow: $drop_shadow_x_large;
    }
    &.aw-widgets-cellListItemSelected {
        background-color: $cell_list_item_selected;
        &:hover {
            background-color: $cell_list_item_selected_hover;
        }
    }
}

.aw-activecollab-feedShadow {
    border-color: $PL_Black_23;
    box-shadow: $drop_shadow_normal;
    -moz-box-shadow: $drop_shadow_small;
    -webkit-box-shadow: $drop_shadow_small;
    background-color: $brand_primary_content_background;
}

ul.aw-widgets-cellListWidget .aw-activecollab-nonIndentedList .aw-widgets-cellListItem {
    background-color: $brand_primary_content_background;
    border-color: $brand_primary_content_background;
    &:hover {
        background-color: $brand_primary_content_background;
    }
}

.aw-activecollab-quickReply .aw-activecollab-editor{
    box-shadow: $drop_shadow_small;
}
.aw-activecollab-quickReply .aw-activecollab-editor .ck-editor__editable_inline {
    border-color: $PL_Black_16;
}
.aw-activecollab-create.aw-activecollab-editor .ck-editor__editable_inline {
    border-color: $PL_Black_16;
}

.aw-activecollab-commentEditButton button a {
    color: $Siemens_Snow;
}

.aw-activecollab-disableCKEditor {
    border-color: $brand_disabled_border;
    background: $brand_disabled_background;
    color: $brand_disabled_text;
}

.aw-activecollab-openStatus {
    border-color: $brand_main_focus_color;
}

.aw-activecollab-closedStatus {
    border-color: $Status_Gray_7;
}

.aw-activecollab-inProgressStatus {
    border-color: $Status_Green_5;
}

.aw-activecollab-snapshotTile {
    border-color: $aw_gray_23;
}
