/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Styling for Kanban board */

.aw-kanbanInterface-kanban {

    .webix-kaban-wrapper .webix_accordionitem_label {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .webix_kanban_list_content:focus,
    .webix_accordionitem .webix_accordionitem_header:focus {
        outline-width: 2px;
        outline-style: solid;
        outline-offset: 2px;
    }

    .webix_accordionitem_header {
        margin: 8px 4px 8px 4px;
    }

    .webix_kanban_body {
        padding: rem-calc(1);
        align-self: center;
        width: rem-calc(53);
        height: rem-calc(53);
    }

    .webix_kanban_tags {
        padding-top: rem-calc(10);
        width: 100%;
    }

}

.kanban_full_width {
    width: 100vw;
}
