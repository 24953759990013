/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Styling for Kanban board */
.aw-kanbanInterface-kanban {

    .webix_accordionitem.horizontal.collapsed,
    .webix_accordionitem.collapsed,
    .webix_accordionitem_header,
    .webix_accordionitem .webix_accordionitem_header:hover,
    .webix_accordionitem .webix_accordionitem_header:focus {
        background-color: $aw-charts-chartColor1;
        color: $brand_primary_content_background;
    }

    .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header,
    .webix_accordionitem.collapsed .webix_accordionitem_header,
    .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:hover,
    .webix_accordionitem.collapsed .webix_accordionitem_header:hover,
    .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:focus,
    .webix_accordionitem.collapsed .webix_accordionitem_header:focus {
        background-color: $aw-charts-chartColor1;
        color: $brand_primary_content_background;
    }

    .webix_list_item.webix_selected.webix_kanban_list_item .webix_kanban_list_content {
        background-color: $brand_selection_background_selected;
    }

    .webix_list_item.webix_selected.webix_kanban_list_item .webix_kanban_list_content:hover {
        background-color: $brand_selection_background_selected_hover;
    }

    .webix_list_item.webix_kanban_list_item .webix_kanban_list_content:hover {
        background-color: $brand_selection_background_hover;
    }

    .aw-schedulemanager-priorityLowest .webix_kanban_list_content,
    .aw-schedulemanager-priorityLow .webix_kanban_list_content {
        border-left-color: $aw-charts-chartColor1;
    }

    .aw-schedulemanager-priorityMediumLow .webix_kanban_list_content,
    .aw-schedulemanager-priorityMedium .webix_kanban_list_content {
        border-left-color: $aw-charts-chartColor2;
    }

    .aw-schedulemanager-priorityHigh .webix_kanban_list_content {
        border-left-color: $aw-charts-chartColor9;
    }

    .aw-schedulemanager-priorityVeryHigh .webix_kanban_list_content,
    .aw-schedulemanager-priorityHighest .webix_kanban_list_content {
        border-left-color: $aw_ns_red;
    }

    .webix_kanban_list:focus,
    .webix_kanban_list_content:focus,
    .webix_accordionitem .webix_accordionitem_header:focus {
        outline-color: $brand_main_focus_color;
    }

}
