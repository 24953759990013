// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*=========================================================================*/
/*============================ EP Bop Breakdown ===========================*/
/*=========================================================================*/

.aw-ep-highLevelPlanningGuidanceMsg{
    .guidance-container-fullWidth{
        height: rem-calc(33);
    }
    .aw-guidance-message .aw-guidanceText-container{
        align-self: center;
    }
}
