/* Copyright 2022 Siemens Product Lifecycle Management Software Inc. */

.aw-non-working-hours{
    background-color: $brand_chip_background;
}

.aw-calendarManagement-selectedDay {
    background-color:$Siemens_Blue_7;
}

.aw-calendarManagement-exception {
    background-color: $Status_Orange_9;
}

.flatpickr-day.selected.aw-calendarManagement-exception
{
    outline: $Status_LightBlue_9;
}

.aw-calendarManagement-nonWorking {
    background-color: $Status_Gray_10;
}

.aw-calendarManagement-inheritedException {
    background-color: $Status_Orange_5;
    color: $Siemens_Snow;
}

.aw-calendar .sw-property-val.sw-changed {
    background-color: $Siemens_Snow;
}
