// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

// mixins
@import 'mixins/mixins';

.aw-cfg-fullScreenPage {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.aw-cfg-fscColumnLayoutHorizontalMode {
    height: 100%;
}

.aw-cfg-fscPropLabel .aw-widgets-propertyContainer>aw-property-label {
    flex: auto;
}

.aw-cfg-fscSummaryHeader .aw-widgets-cellListCellTitleBlock {
    // padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.aw-cfg-fscIndicatorContainer {
    display: flex;
}

.aw-cfg-fscSummarySectionLabel {
    cursor: context-menu;
    width: 100%;
    white-space: nowrap;
    font-weight: bold;
}

button.aw-cfg-fscSummaryChevronStyle.aw-base-iconButton {
    padding: 1rem;
    padding-top: 0px;
    height: 2rem;
    width: 2.5rem;
    margin-left: -15px;
}

.aw-cfg-fscSummaryVariantLabel {
    margin-left: 16px; // do same alignment as in panelSectionContent
}

.aw-cfg-fscSummaryVariantCell {
    padding-left: $padding_small;
}

.aw-cfg-fscSummaryModelImage {
    margin: 0 auto;

    .aw-xrt-thumbnailImage {
        width: 180px;
        margin: 0 auto;
    }
}

.aw-cfg-fscSummaryPadding {
    padding: 0 $padding_xlarge 0 $padding_xlarge;
}

.aw-cfg-fscSummaryUserSelectionsList .aw-widgets-cellListWidget .aw-widgets-cellListItem:hover {
    background-color: unset; // Disable hover
}

.aw-cfg-fscSummaryProductModelSelection.aw-layout-panelSection {
    margin-left: 20px;
    margin-bottom: -10px;

    .aw-layout-panelSectionContent {
        margin-bottom: 10px; // instead of default 24px
    }
}

.aw-cfg-fscSummaryUserSelectionFamily.aw-layout-panelSection {
    margin-left: 20px;

    .aw-layout-panelSectionContent {
        margin-bottom: 0;
    }
}

.aw-cfg-fscUserSelectionTextContainer {
    margin-left: 15px;
}

.aw-layout-flexRow.aw-cfg-fscProductModelSelectionVMOContainer {
    margin-top: -10px;
    margin-left: 5px;
}

.aw-layout-flexRow.aw-cfg-fscUserSelectionVMOContainer {
    margin-top: -10px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.aw-cfg-fscSummaryExcludeLabel {
    display: inline-block;
    margin-left: 10px;
}

.aw-cfg-fscSummaryExcludeLabel svg {
    width: 16px;
    height: 16px;
}

.aw-cfg-fscSummaryUserSelectionCell {
    display: inline-block;
}

.aw-cfg-fscSummaryProductSelectionCell {
    margin-bottom: 5px;
    text-align: center;
}

/**
VARIABILITY TREES style classes
TODO: adjust to style guidelines
*/

// Configuration links such as revision rule, rule date and effctivity links
// loose their alignment when used in aw-row/aw-flex-row i.e. in VCA.
// Keeping margin alignement local to VCA as not needed in FSC & Variability Explorer.
#vcaFilterCriteriaContainer .aw-cfg-vcaConfigurationLinks {
    margin-top: 0;
}

.aw-cfg-variantgridGuidanceMessage {
    padding-bottom: 3px;
}

.aw-cfg-variantgrid .aw-splm-tableHeaderCell .aw-splm-tableColumnMenuEnabled{
    cursor: context-menu; //overwrite to display regular cursor instead of pointer when columnMenu is enabled
}

.aw-cfg-variantgridFilterHeader{
    display: block;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    cursor: context-menu; // Overwrite 'auto' cursor on text
}

.aw-cfg-variantgridHeader {
    display: block;
    writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: context-menu; // Overwrite 'auto' cursor on text
}
.aw-cfg-variantgridHeader .aw-splm-tableCellContents{
    align-items: end;
}
.aw-cfg-variantgridHeaderIconEmpty {
    height: 15px;
    width: 5px;
    cursor: context-menu;
}

.aw-cfg-variantgridCell {
    align-items: center;
    justify-content: center;
    height: rem-calc(28);
    border-right: rem-calc(1) solid;
    border-color: gainsboro;
    overflow: hidden;
    padding: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.aw-cfg-variantgridTextCell {
    margin-left: $margin_small;
    margin-top: $margin_small;
}

.aw-cfg-img-gridcell {
    margin-left: $margin_small;
}

.aw-cfg-variantgrid .aw-widgets-dataGridCellImage {
    width: rem-calc(28);
}

.aw-ui-filterCategoryLabel.aw-cfg-familyLabel {
    font-size: $fontsize_large;
    margin-left: 22px;
    cursor: default;
}

.aw-ui-filterCategoryLabel.aw-cfg-groupLabel {
    cursor: default;
}

.aw-cfg-familyWrapper {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}

.aw-cfg-familyWrapper:first-child {
    margin-top: 0;
}

.aw-cfg-familyHighlightedForCompletenessCheck .aw-layout-collapsiblePanelSectionTitle {
    border-style: solid;
    border-width: rem-calc(2);
}

.aw-cfg-optionValueLabelSelected {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: normal;
    margin-left: 13px;
    padding-left: 9px;
}

.aw-cfg-selectedImage {
    margin-left: -4px;
    margin-right: 4px;
    display: inline-block;
    width: 0px;
}

.aw-cfg-selectedImageThumbnail svg,
.aw-cfg-selectedImage svg {
    width: 16px;
    height: 19px;
}

.aw-cfg-selectedImageThumbnail {
    margin-left: -8px;
    margin-right: 8px;
    display: inline-block;
    width: 0;
}

.aw-cfg-violationImage {
    margin-left: $margin_large;
    vertical-align: middle;
}

.aw-cfg-violationImageTooltip {
    margin-top: $margin_large;
}

.aw-cfg-disableSelection {
    pointer-events: none;
    opacity: 0.4;
}

.aw-cfg-violationLabel {
    margin-top: 10px;
}

.aw-cfg-rangeInfo {
    margin-top: 5px;
}

.aw-cfg-fsc-freeFormIndicatorBar svg,
.aw-cfg-violationImageTooltip svg,
.aw-cfg-violationImage svg {
    width: 16px;
    height: 16px;
}

.aw-cfg-completeIndicatorLabel {
    font-size: $fontsize_normal;
    font-style: italic;
    margin-left: 4px;
}

.aw-cfg-optionValueCellRow {
    -webkit-align-items: center;
    align-items: center;
}

.aw-cfg-optionValueCell {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    margin-right: 0;
    width: 80%;
}

.aw-cfg-optionValueCellSelected {
    margin-left: 2px;
}

.aw-cfg-optionValueCellDeselected {
    margin-left: 17px;
}

.aw-cfg-summarySection {
    margin-left: 20px;
}

.aw-cfg-freeFormSection {
    width: 95%;
}

.aw-cfg-freeFormRow {
    height: 38px;
}

.aw-cfg-freeFormText {
    padding-top: 5px;
}

.aw-cfg-freeFormTextCommandBar {
    margin-left: 5px;
    flex-direction: row;
}

.aw-cfg-freeFormDateCommandBar {
    flex-direction: row;
}

.aw-cfg-selectBox {
    margin-bottom: 20px;
}

.aw-cfg-summaryInfo {
    margin-top: 10px;
}

.aw-cfg-variantgrid .ui-grid-top-panel .ui-grid-header-viewport .ui-grid-header-canvas .ui-grid-filter-container {
    padding: 50px 10px;
}

.aw-cfg-variantgrid .aw-jswidgets-grid .ui-grid-row .ui-grid-cell {
    border-top-width: 1px;
    border-left-style: ridge;
}

.aw-cfg-variantgrid .ui-grid-cell-contents.ui-grid-header-cell-primary-focus {
    padding-top: 52px;
    margin-left: 5px;
    margin-bottom: -49px;
    text-transform: none;
    font-weight: 600;
}

.aw-cfg-colheader {
    align-items: center;
    height: inherit;
}

.aw-cfg-colheaderValidationCellDecorator {
    border-left-width: 4px;
    border-left-style: solid;
    margin-left: -5px;
}

.aw-cfg-rotatetext {
    transform: translate(0, 40px) rotate(-90deg);
    text-transform: none;
}

.aw-cfg-colheadersize {
    min-width: 34px;
    max-width: 34px;
}

.aw-cfg-celllayout {
    overflow: hidden;
}

.aw-cfg-tooltip .aw-cfg-tooltiptext {
    z-index: 1;
    top: 0;
    left: 24px;
    text-transform: none;
    text-align: left;
    word-wrap: break-word;
    padding: 5px 5px;
    font-weight: lighter;
    width: 300px;
    max-height: 100px;
    overflow-y: scroll;
    white-space: pre-line;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.aw-cfg-tooltip:hover .aw-cfg-tooltiptext {
    visibility: visible;
}

.aw-cfg-variantgridheight {
    height: 100%;
}

.aw-cfg-packageInfoCommand {
    margin-left: auto;
    margin-right: 2px;
}

.aw-cfg-familyRequiredLabel {
    display: inline-block;
}

.aw-cfg-familyRequiredLabel svg {
    width: 16px;
    height: 16px;
}

.aw-cfg-systemSelection {
    width: 16px;
    display: inline-block;
    margin-left: auto;
    margin-right: 10px;
}

.aw-cfg-systemSelectionThumbnail svg,
.aw-cfg-systemSelection svg {
    width: 16px;
    height: 16px;
}

.aw-cfg-systemSelectionThumbnail {
    width: 16px;
    display: inline-block;
    margin-left: auto;
}

.aw-cfg-IndicatorContainer {
    @include flexbox();
    margin-left: auto;
}

.aw-ui-filterCategoryLabel.aw-cfg-summaryLabel {
    font-size: $fontsize_large;
    cursor: default;
}

.aw-cfg-summaryRequiredLabel {
    display: inline-block;
    margin-left: auto;
}

.aw-cfg-summaryRequiredLabel svg {
    width: 16px;
    height: 16px;
}

.aw-cfg-innerFlowPanel {
    z-index: 1001;
}

.aw-cfg-dateEfftyPopupOnCfgPanel {
    max-height: 400px;
}

.aw-cfg-mainScrollPanel {
    max-height: 400px;
    max-width: 300px;
    min-width: 200px;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
}

.aw-cfg-separator {
    border-top: solid 1px;
    pointer-events: none;
    text-decoration: none;
    cursor: default;
}

.aw-cfg-popupScrollPanel {
    max-height: 300px;
    width: 300px;
    word-wrap: break-word;
}

.aw-cfg-fscSelectedImage {
    margin-right: 4px;
    display: inline-block;
    width: 0;
}

.aw-cfg-fscSelectedImage svg {
    width: 16px;
    height: 19px;
}

.aw-cfg-fscIndicatorContainer {
    @include flexbox();
    margin-left: 5px;
    padding-top: 4px;
}

.aw-cfg-fscSelectedImageThumbnail {
    align-self: center;
    //no margins anymore, they get overwritten
    display: inline-block;
    width: 0;
}

.aw-cfg-fscSelectedImageThumbnail svg {
    width: 16px;
    height: 19px;
}

.aw-cfg-FreeFormIcon {
    align-self: center;
    width: fit-content;
}

.aw-cfg-freeFormDate {
    margin-top: 0.2rem;
}

.aw-cfg-fscOptionValueCell {
    padding-right: 0;
    margin-right: 0;
    flex-direction: row;
}

.aw-cfg-fscOptionValueCellNonFreeForm {
    padding-left: 15px;
}

.aw-cfg-fscOptionValueCell .aw-widgets-cellListCellContent {
    float: unset;
    width: 100%;
    overflow: hidden;
}

.aw-cfg-fscValueNoThumbnail {
    margin-left: 10px;
}

.aw-cfg-fscValueThumbnail {
    margin: 0 auto;
}

.aw-cfg-fscFreeFormRangeExpEnumValue {
    margin-left: rem-calc(7);
    padding-left: rem-calc(6);
    margin-top: rem-calc(16);
}

.aw-cfg-fscFreeFormRangeExpDateWidget {
    margin-left: rem-calc(7);
    padding-left: rem-calc(6);
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(11);
}

.aw-cfg-fscFreeFormToFromRange {
    width: rem-calc(60);
}

.aw-cfg-revisionruleCheckBox {
    font-weight: bold;
}

.aw-cfg-revisionruleValue {
    padding-left: rem-calc(27);
    margin-top: rem-calc(-8);
}

.aw-cfg-freeFormStringValue {
    margin-bottom: -10px;
}

.aw-cfg-freeFormEqualsOperator {
    margin-top: 8px;
}

.aw-cfg-valueLabel {
    margin-left: $margin_large;
    overflow: hidden;
    width: calc(90%);
    text-overflow: ellipsis;
}

.aw-cfg-freeFormIndicator {
    padding-left: $checkbox_check_width; // do same alignment as the above textbox, margin might get overwridden, order is not guaranteed
}

.aw-cfg-summaryChevron {
    margin-top: -0.6rem;
}

.aw-panel-footer.aw-cfg-settingsFooter {
    padding-bottom: 0; //override the padding at the bottom as the overall padding of 16px is the standard
}

.aw-xrt-summaryXrt .aw-cfg-fullScreenPage .aw-layout-column {
    padding-right: 0; //need to overwrite the aw-xrt-summaryXrt padding-right: 10px, todo remove if fixed by the framework
}

.aw-cfg-fscIndicatorContainer.aw-cfg-fscSummarySectionIndicatorContainer {
    @include flexbox();
    margin-left: rem-calc(-10);
    padding-top: 0;
}

.keyboard .aw-cfg-FreeFormIcon.sw-aria-border:focus {
    padding: 0.2rem;
    height: 2.4rem;
}

.aw-cfg-value{
    padding: rem-calc(5);
    padding-bottom: 0;
}


//temporary until we get aw-label in the property grid, we need this to avoid showing : during refresh
.aw-cfg-tempLabel .sw-property-name:not(:empty)::after { 
    content: ":";
}

.aw-cfg-tooltipViolationLabel {
    padding-left: $padding_small;
}
.aw-cfg-tooltipViolationPanel {
    padding: $padding_small;
}

