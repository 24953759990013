// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*=========================================================================*/
/*========================== EP Activities - CATEGORY =====================*/
/*=========================================================================*/
.aw-epActivities-categoryWidgetNa {
    background-color: $Status_Gray_Base;
}

.aw-epActivities-categoryWidgetVa {
    background-color: $Status_Green_Base;
}

.aw-epActivities-categoryWidgetNva {
    border-color: $Status_Red_Base;
}

.aw-epActivities-categoryWidgetNvabr::before {
    border-color: $Status_Yellow_Base;
}

.aw-epActivities-categoryWidgetNvabr::after {
    background-color: $Status_Yellow_Base;
}

/*=========================================================================*/
/*========================== EP Activities - CATEGORY =====================*/
/*=========================================================================*/

/*=========================================================================*/
/*========================== EP Activities - data card ====================*/
/*=========================================================================*/

.aw-ep-dataCardPropertyPanel{
    border-left-color: $brand_secondary_box_shadow;
}

/*=========================================================================*/
/*========================== EP Activities - data card ====================*/
/*=========================================================================*/
