// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*========================================================================*/
/*===========================MFE Manage Work Package  ==========================*/
/*========================================================================*/

.aw-ep-manageStructureTilesArea {
    height: fit-content;
}

.aw-mfe-manageWpXrtContainer {
    padding : 0 ($padding_xlarge + $padding_normal);

    .aw-xrt-separator {
        margin: ($margin_normal + $margin_xsmall) 0;
        padding-bottom:0;
    }

    .aw-layout-panelContent {
        padding: 0;
    }
}

.aw-manageTile-title {
    font-size: $fontsize_large;
    font-weight: $bold_font_weight;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.aw-mfe-tileSeparator {
    height: rem-calc(24);
}

.aw-mfe-manageWpTileRevRuleContent {
    margin-right: rem-calc(5);
}

.aw-mfe-manageWpTileRevRuleVal {
    font-weight: $bold_font_weight;
}

.aw-ep-managePageColumn {
    border-left-width: rem-calc(1);
    border-left-style: solid;
    padding: 0 2rem;
}

.aw-ep-managePageColumnTitle {
    font-weight: 600;
    font-style: normal;
    font-size: rem-calc(21);
}

.aw-ep-managePageOverviewColumnTitle,
.aw-ep-managePageTilesColumnTitle,
.aw-ep-managePageTasksColumnTitle  {
    margin-bottom: rem-calc(30);
    margin-top: ($margin_large + $margin_xsmall);
    height: rem-calc(24);
}

.aw-ep-manageWPHeaderTileAreaOffset {
    overflow: scroll;
    .aw-mfe-contentPanel{
        min-height: rem-calc(72);
    }
}

.aw-mfe-manageWpTileContainer {
    height: rem-calc(72);
    padding-right: $padding_xlarge;
}

.aw-mfe-productVariantsContainer {
    padding: $padding_xlarge;
    gap: $margin_large;
}

.aw-mfe-epProductVariantsTilesTitle {
    font-size: $fontsize_large;
    font-weight: 600;
}

.aw-advanced-bar.aw-advanced-bar-horizontal.aw-mfe-epProductVariantsTiles {
    border: none;
    box-shadow: none;
}

.aw-mfe-contentPanel.aw-mfe-epProductVariantsTilesViews{
    border: $default_border;
}

.aw-mfe-contentPanelContainer .aw-mfe-contentPanel.aw-mfe-epProductVariantsTilesViews{
	border: 1px solid;
}

.aw-mfe-epProductVariantsTilesViews {
    .aw-mfe-messageLine {
        padding : 0 $padding_normal;
    }
}

.aw-epHeader-separator.aw-mfe-separator.aw-theme-separator {
    height: rem-calc(20);
    margin: 0 $margin_normal;
}

// ========= Anchor Commands on Tiles =========
@mixin full-width {
    width: 100%;
    height: 100%;
}

div[anchor$="_tileAnchor"] {
    @include full-width;

    .aw-commands-wrapper {
        @include full-width;

        .aw-command {
            @include full-width;

            button.aw-commands-commandIconButton.aw-commands-command.aw-commandId-epGroupAddToWorkpackage.aw-commands-commandWrapperHorizontal,
            button.aw-commands-commandIconButton.aw-commands-command.aw-commandId-epAddStructureToWorkpackageTileCommand.aw-commands-commandWrapperHorizontal {
                @include full-width;
                max-width: 100%;
                text-align: center;
            }
        }
    }
}

// =========  To be used by Tile commands shown inside Popups =========
li[command-id$="TileCommand"].aw-widgets-cellListItem {
    min-width: rem-calc(420);
}

.aw-epSearchObject-box{
    border-style: solid;
    border-width: rem-calc(1);
    height: rem-calc(250);
}

// ========== To be used by update Revision command====================
.aw-mbm-availableRevisionTableTitle {
    height: rem-calc(36);
    display: flex;
    align-items: center;
    padding: 0 $padding_normal;
    gap: $margin_small;
    font-weight: bold;
}
