/* Copyright 2019 Siemens Product Lifecycle Management Software Inc. */

// temp color def -- moving to afx soon...
$DataVis_Purple_6: #C698B0;
// Purple
// lighten
$Charting_Purple_5: lighten($Charting_Purple_Base, 20%); // #B47797
$Charting_Purple_7: lighten($Charting_Purple_Base, 40%); // #D8B9C9

// Teal
// darken
$Charting_Teal_3: darken($Charting_Teal_Base, 20%); // #4C7F79
// base
$Charting_Teal_5: $Charting_Teal_Base; // #7FB2AC
// lighten
$Charting_Teal_6: lighten($Charting_Teal_Base, 10%); // #9FC5C1

.saw1-border-chartColor1 div.aw-default-cell {
    border-color: $Charting_Yellow_Base;
}

.saw1-border-chartColor2 div.aw-default-cell {
    border-color: $Charting_Green_Base;
}


.smgantt {

    .gantt_baseline {
        background-color: $Charting_Yellow_Base;
    }

    .gantt_task_line.gantt_task_inline_color {
        border: 1px solid $PL_Black_4;
        box-shadow: 0px 1px 2px $PL_Black_Shade_2;
    }

    .summary_task,
    .summary_task.has_baseline {
        background-color: $Charting_Purple_7;
    }

    .gantt_task_line.summary_task.gantt_task_inline_color {
        .gantt_task_progress {
            background-color: $Charting_Purple_5;
        }
    }

    .schedule_task,
    .schedule_task.has_baseline {
        background-color: $Charting_Teal_6;
    }

    .gantt_task_line.schedule_task.gantt_task_inline_color {
        .gantt_task_progress {
            background-color: $Charting_Teal_5;
        }
    }

    .proxy_task,
    .proxy_task.has_baseline {
        background-color: $Charting_Brown_9;
    }

    .gantt_task_line.proxy_task.gantt_task_inline_color {
        .gantt_task_progress {
            background-color: $Charting_Brown_7;
        }
    }

    .has_baseline {
        background-color: $Charting_Yellow_Base;
        border-color: $Charting_Yellow_Base;
    }

    .gantt_baseline.gantt_baseline2_milestone,
    .gantt_baseline.baseline2 {
        background-color: $Charting_Green_Base;
    }

    .milestone {
        border-color: $Siemens_Snow;
    }

    .gantt_task_line.milestone.gantt_milestone.gantt_task_inline_color {
        .gantt_task_content {
            background: $Charting_Teal_5;
            border: 1px solid $PL_Black_4;
            box-shadow: 0px 1px 2px $PL_Black_Shade_2;
        }
    }

    .selected_link {
        .gantt_line_wrapper div {
            background-color: $brand_primary_accent_button_background_hover;
            box-shadow: 0 0 10px 0 $PL_Black_8;
        }
        .gantt_link_arrow_right {
            border-left-color:$brand_primary_accent_button_background_hover;
        }
        .gantt_link_arrow_left {
            border-right-color: $brand_primary_accent_button_background_hover;
        }
    }

    .gantt_task_cell {
        border-right-color: $aw_line_color;
    }

    .gantt_task_row {
        border-bottom-color: $aw_line_color;
    }

    .gantt_marker.week_end {
        background-color: $aw_line_color;
    }

    .gantt_tooltip {
        background-color: $balloon_popup_background_color;
        border-color: $balloon_popup_border_color;
        box-shadow: $balloon_popup_box_shadow;
    }

    .gantt_container,
    .gantt_scale_line {
        border-color: $table_border_color;
    }

    .gantt_scale_line:nth-child(2) .gantt_scale_cell,
    .gantt_scale_line:nth-child(3) .gantt_scale_cell {
        border-color: $table_border_color;
    }

    .gantt_scale_cell {
        border-color: $drop_shadow_table_scroll_color;
    }

    .gantt_grid_scale {
        border-bottom-color: $table_border_color;
        background-color: $table_header_background_color;
    }

    .gantt_side_content,
    .gantt_task_text {
        color: $brand_primary_accent_text;
    }

    .gantt_grid_data .task_added_in_whatif_mode .gantt_tree_content {
        color: $aw_gantt_created_what_if;
    }

    .gantt_grid_data .modified_in_whatif_mode .gantt_tree_content {
        color: $AW_SiemensAccentRed4;
    }

    .gantt_task_bg {
        background-color: $brand_primary_content_background;
    }

    .gantt_task_line.event.gantt_milestone.gantt_selected .gantt_task_content {
        border-color: $aw_prg_timeline_event_selection;
        box-shadow: 0 0 5px $aw_gray_16;
    }

    .gantt_task_stackedWithInfo, .gantt_task_stackedWithoutInfo{
        background-color: $Siemens_Blue_20;
    }

    .gantt_task_stackedWithInfo .gantt_task_stackedCount,
    .gantt_task_stackedWithoutInfo .gantt_task_stackedCount{
        color: $PL_Black;
    }

    .gantt_task_stackedWithInfoCompact,
    .gantt_task_stackedWithoutInfoCompact,
    .gantt_task_stackedWithInfoCompactAndFind{
        background-color: $Siemens_Blue_20;
    }

    .gantt_task_stackedWithInfoCompact .gantt_task_stackedCount ,
    .gantt_task_stackedWithoutInfoCompact .gantt_task_stackedCount,
    .gantt_task_stackedWithInfoCompactAndFind .gantt_task_stackedCount{
        color: $PL_Black;
    }

    .gantt_task_line.gantt_milestone .gantt_task_content {
        background: $Charting_Teal_3;
    }

    .gantt_row.task_added_in_whatif_mode .gantt_cell:first-child .gantt_tree_content {
        color: $aw_gantt_created_what_if;
    }

    .gantt_row.modified_in_whatif_mode .gantt_cell:first-child .gantt_tree_content {
        color: $AW_SiemensAccentRed4;
    }

    .gantt_task_link:hover .gantt_line_wrapper div {
        box-shadow: 0 0 10px 0 $PL_Black_8;
    }

    .gantt_baseline,
    .gantt_base_milestone {
        background: $aw_gantt_baseline;
        border-color: $aw_gray_10;
    }
}

.aw-schedulemanager-taskAddedInWhatIfMode {
    font-weight: bold;
    color: $aw_gantt_created_what_if;
}

.aw-schedulemanager-taskModifiedInWhatIfMode {
    font-weight: bold;
    color: $AW_SiemensAccentRed4;
}

.aw-scheduleManager-resourceLoad25 {
    border-color: $Status_Orange_10;
    background-color: $Status_Orange_10;
}

.aw-scheduleManager-resourceLoad50 {
    border-color: $Status_Orange_9;
    background-color: $Status_Orange_9;
}

.aw-scheduleManager-resourceLoad75 {
    border-color: $Status_Green_9;
    background-color: $Status_Green_9;
}

.aw-scheduleManager-resourceLoad100 {
    border-color: $Status_Green_8;
    background-color: $Status_Green_8;
}

.aw-scheduleManager-resourceLoad101 {
    border-color: $Status_Red_7;
    background-color: $Status_Red_7;
}

.aw-scheduleManager-resourceLoadWeekend {
    border-color: $brand_chip_background;
    background-color: $brand_chip_background;
}

.aw-scheduleManager-ganttTreeContainer .aw-splm-tableContainer {
    background-color: $table_content_background_color;
}
