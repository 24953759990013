// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== EP Effectivity Popup =========================*/
/*=========================================================================*/

.aw-epInstructionsEffectivity-wiValidateEffectivityPopup {

    .aw-epInstructionsEffectivity-summaryRowContainer {
        box-shadow: $brand_primary_box_shadow;
    }

    .aw-epInstructionsEffectivity-rowNameElement {
        color: $brand_secondary_content_text;
    }

    .aw-epInstructionsEffectivity-upCheckboxDefault {
        border-color: $brand_primary_border_focus;
        color: $brand_secondary_background;
    }

    .aw-epInstructionsEffectivity-upCheckboxSummary {

        border-color: $brand_primary_border_focus;
        color: $brand_secondary_background;
    }

    .aw-epInstructionsEffectivity-upCheckboxSet {
        border-color: $brand_primary_border_focus;
        color: $brand_primary_content_background;
        background-color: $brand_secondary_background;
    }

    .aw-epValidateEffectivity-wiEffectivitySliderCircleInner {
        fill: $brand_primary_content_background;
    }

    .aw-epValidateEffectivity-wiEffectivitySliderCircleOuter {
        fill: $aw_gray_4;
    }

    .aw-epValidateEffectivity-wiEffectivitySlideRect {
        fill: $aw_gray_4;
    }

    #wiEffectivityToolTip {
        background-color: $brand_secondary_content_text;
        color: $brand_primary_inverse_text;
        border: $brand_primary_border;
    }

    #wiEffectivityToolTip::after {
        border-color: $brand_tertiary_border  transparent transparent transparent;
    }
}
.aw-ep-unitWithGap{
    fill: $Siemens_Yellow_17;
    stroke-width: 1;
    stroke: $Siemens_Snow;
}

.aw-ep-unitWithOverlap{
    fill: $Siemens_Status_Red;
    stroke-width: 1;
    stroke: $Siemens_Snow;
}

.aw-ep-unitWithEffectivity{
    fill: $PL_Black_13;
    stroke-width: 1;
    stroke: $Siemens_Snow;
}

.aw-ep-defaultUnit{
    fill: $Charting_Grey_9;
    stroke-width: 1;
    stroke: $Siemens_Snow;
}

.aw-epValidateEffectivity-blankUnit{
    fill:$Siemens_Snow;
}

