// Copyright 2020 Siemens Product Lifecycle Management Software Inc.

.aw-epGraphics-thumbnailBorder {
    border-color: $Siemens_Yellow_13;
}

.aw-epGraphics-visualsGalleryBorder {
    border-color: $brand_search_prefilter_border;
}

.aw-commandId-epVisualsGallerySaveEdits {
    .aw-theme-iconOutline {
        fill: $brand_primary_border_focus;
    }

    .aw-commands-commandIconButtonText {
        color: $brand_primary_border_focus;
    }
}
