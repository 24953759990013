// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* ================================ PRI ================================ */
.aw-epPri-container {
    background-color: $PL_Black_26;
}

.aw-epPri-name {
    color: $PL_Black_12;
}

.aw-epPri-containerCapacity {
    color: $Siemens_Blue_Light_Old;
}

.aw-epPri-time {
    color: $PL_Black_12;
}

.aw-epPri-item {
    fill: $Natural_Blue_9;
}

.aw-epPri-item:hover:not(.aw-epPri-externalItem),
.aw-epPri-item.aw-epPri-itemSelected {
    fill: $Natural_Blue_8;
}

.aw-epPri-item.aw-epPri-itemSelected {
    stroke: $Siemens_Blue_Light_Old;
}

.aw-epPri-exceedingItem {
    fill: $lb-exceeding-time-color;
}

.aw-epPri-externalItem {
    fill: $Charting_Black_10;
}

.aw-epPri-gap:HOVER~.aw-epPri-gapBorder {
    stroke: $Natural_Blue_9;
}

.aw-epPri-time {
    fill: $PL_Black_12;
}

.aw-epPri-port {
    fill: $Siemens_Blue_11;
}

.aw-epPri-arrow {
    fill: none;
}

.aw-epPri-existingArrow,
.aw-epPri-currentArrow {
    stroke: $Charting_Grey_6;
}

.aw-epPri-hoveredArrow {
    stroke: $lb-ok-time-color;
}

.aw-epPri-existingArrowHead {
    fill: $Charting_Grey_6;
}

.aw-epPri-hoveredArrowHead {
    fill: $lb-ok-time-color;
}

.aw-epPri-selectedArrowHead {
    stroke: $Charting_Grey_3;
}

.aw-epPri-mainTaktTimeLine {
    stroke: $PL_Black_10;
}

.aw-epPri-secondaryTaktTimeLine {
    stroke: $Charting_Black_10;
}

.aw-epPri-taktTimeText {
    fill: $PL_Black_10;
}

.aw-epPri-cycleTimeNormal .aw-epPri-cycleTimeLine {
    stroke: $Siemens_Blue_Light_Old;
}

.aw-epPri-cycleTimeExceeding .aw-epPri-cycleTimeLine {
    stroke: $lb-exceeding-time-color;
}

.aw-epPri-cycleTimeNormal text,
.aw-epPri-availableTime {
    fill: $Siemens_Blue_Light_Old !important;
}

.aw-epPri-cycleTimeExceeding text,
.aw-epPri-exceedingTime {
    fill: $lb-exceeding-time-color !important;
}

.aw-epPri-dropTarget {
    stroke: $PL_Black_12;
}

.aw-epPri-containerAxis,
.aw-epPri-axisLine {
    fill: none;
    stroke: $PL_Black_20;
}

.aw-epPri-externalArrowHead {
    fill: $Charting_Grey_6;
}

.aw-epPri-externalArrow {
    stroke: $Charting_Grey_6;
}

.aw-epPri-selectedProcessResource {
    background-color: $Natural_Blue_10;
}

/* ================================ PRI ================================ */
