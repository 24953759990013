// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-ep-partialVisibilityWithDashedOutline {
    outline-color: $Status_Gray_7;

}
