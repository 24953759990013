// Copyright 2022 Siemens Product Lifecycle Management Software Inc.

.aw-epGraphics-thumbnailExtension {
    padding-left: rem-calc(3);
}
.aw-epGraphics-editVisualTextbox .aw-widgets-propertyContainer {
    margin-bottom: 0;
}

.aw-epGraphics-epRenameVisualContainer {
    padding: $padding_normal;
}

.aw-epImageViewer-container {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    margin: auto;
}

.aw-epGraphics-visualsGalleryBorder,
.aw-epGraphics-thumbnailBorder {
    border-width: rem-calc(3);
    border-style: solid;
}

.aw-epGraphics-visualsGalleryBorder {
    .aw-widgets-cellListItem {
        margin-top: rem-calc(13);
        &:first-child {
            margin-left: rem-calc(13);
        }
    }
}

.aw-epGraphics-tableCellImageTooltip {
    width: rem-calc(300);
}
