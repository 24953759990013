// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*========================================================================*/
/*=========================== MFE Loading Message ========================*/
/*========================================================================*/

.aw-mfe-messageLine {
    color: $aw_gray_12;
}

.aw-mfe-messageIcon {
    .aw-theme-iconOutline {
        fill: $aw_gray_12;
    }
}
