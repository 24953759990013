// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== PMI ==========================================*/
/*=========================================================================*/

.aw-epInstructionsPmi-highlightedTextColor{
    color: $Siemens_Blue_7;
}

.aw-epInstructionsPmi-guidanceMessageColor{
    border-color: $Siemens_Blue_18;
    background-color: $Siemens_Blue_24;
}

.aw-epInstructionsPmi-methodGroup{
    color: $aw_gray_4;
}

.aw-epInstructionsPmi-refDatumsMessage{
    border-color: $aw_gray_23;
}

.aw-epInstructionsPmi-methodDetail{
    color: $aw_gray_8;
}

#pmiMethodMenuItem.disabled{
    color: $aw_gray_4;
    background-color: transparent;
}
