//----------------------------------------------------
.aw-bmw-comment-body {
    max-height: 255px;
    padding: 0px;
    border: rgb(215, 215, 215) solid;
    border-width: 1px;
    border-radius: 4px;
}

.aw-bmw-comment-body .aw-widgets-cellListCellCommands {
    display: none;
}

.aw-bmw-comment-body li.aw-widgets-cellListItem {
    padding: 5px;
}
.aw-bmw-toolbar-layout{
    border-width: 0 0 0 0 !important;
}
.aw-bmw-commentcell {
    display: flex;
    flex-direction: column;
    font-size: .90rem;
    user-select: text;
}
.aw-bmw-text-selection{
    user-select: all;
    vertical-align: baseline;
}
.aw-bmw-commentcell:hover {
    color: inherit;
}

.aw-bmw-datefield {
    width: 130px;
    margin: 5px;
    float: left;
    border-right: 1px solid white;
    font-weight: 700;
}

.aw-bmw-usernamefiled {
    width: 260px;
    margin: 5px;
    float: left;
    border-right: 1px solid white;
    font-weight: 700;
}

.aw-bmw-commenttypefield {
    padding: 5px;
    min-width: 200px;
    float: left;
}

.aw-bmw-commentsection {
    padding: 5px;
    margin-left: 5px;
    display: block;
    white-space: pre-line;
    border-top: 1px solid white;
    word-wrap: break-word;
}

.aw-bmw-titlesection {
    display: block;
}

.vim-bmw-action-section {
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.vim-bmw-action-section .aw-layout-panelFooter {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.vim-bmw-info-msg {
    font-weight: 600;
}

.vim-bmw-instruction-msg {
    color:rgb(255, 0, 0);
    font-weight: 600;   
}

.vim-bmw-left-align {
    text-align: left;
}
.vim-unread-issue {
    color:rgb(0,113,153);
    font-weight: bold;
}
.vim-bmw-issue-enable-bar {
    background-color: rgb(0,113,153);
    visibility: visible !important;
}

.vim-ftm-priority-issue .aw-splm-tableCellTop .aw-splm-tableCellText {
    font-weight : 700
}

#V4b_showInfoConfigObj {

    aw-popup .aw-popup-container,
    aw-popup-panel2 .aw-popup-container {
        max-width: fit-content !important;
        max-height: fit-content !important;
        min-width: fit-content !important;
        min-height: fit-content !important;
    }


    .aw-splm-tableContainer {
        div[data-indexnumber="1"].ui-grid-cell{
            @extend .ui-grid-cell;
            height: 100px;
            div[class="aw-splm-tableCellTop xxlarge"]{
                height: 100px;
            }
        }
    }
}

.plutoellipse {
    width: 100px;
    /* adjust to your desired width */
    height: 30px;
    /* adjust to your desired height */
    overflow: hidden;
    /* clips the image to the ellipse */
    position: relative;

    border-color: #6bd7ec; 
    border-radius: 5px; 
    cursor: pointer;
}


.plutoellipse aw-img {
    width: 100%;
    /* fills the ellipse with the image */
    height: 100%;
    object-fit: cover;
    display: block;
}

.vimCommentbg {
    background-color: rgb(236, 236, 236);
    width: 100%;
}

.date-containers { 
    flex-direction: row;
    justify-content: space-between;
    }

.label_item {
    width: auto;
    min-width: 79%;
    max-width: max-content;    
}


@media only screen and (max-width: 684px) {
    .label_item {
        width: 100%;
    }
}
@media only screen and (max-width: 600px) and (min-width: 0px) {
    .label_item {
        width: 88%;
    }
}

.table-container {
    height: 750px;
}
#vimGrid.aw-splm-table {
    height: 100%;
}

.v4b-repeat-checkbox .sw-property .sw-row {
    margin-bottom: 0
}
.v4b-app-seach-panel .aw-toolbar-layout .aw-commands-wrapper{
    background-color: white;
    margin-top: -8px;
}

.v4b-wf-button{
    width: 100%;
    margin: 0 !important;
    margin-bottom: 8px !important; 
}
.v4b-panel-position{
    margin-left: -18px;
}