// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*========================================================================*/
/*============================= MFE Pert ==============================*/
/*========================================================================*/

.aw-mfePert-border {
    border-color: $brand_secondary_border;
}

.aw-mfePert-editMode {
    .aw-mfePert-moveIconButton {
        &:hover {
            background-color: $brand_selection_background_hover;
        }
    }

    .aw-mfePert-editIconButton {
        .aw-theme-iconOutline{
            fill: $brand_base_button_background
        }
        background-color: $brand_secondary_background;
    }
}

.aw-mfePert-moveMode {
    .aw-mfePert-editIconButton {
        &:hover {
            background-color: $brand_selection_background_hover;
        }
    }

    .aw-mfePert-moveIconButton {
        .aw-theme-iconOutline{
            fill: $brand_base_button_background
        }
        background-color: $brand_secondary_background;
    }
}

.aw-layout-popup.aw-mfePert-togglePopupMargin{
    box-shadow: $drop_shadow_small;
    border-color: $brand_secondary_border;
}
