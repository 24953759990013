// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@
.aw-epCreateObject-panel {
    $font_weight_400: 400;

    .aw-epCreateObject-headerMargin {
        margin-left: $margin_small;
    }

    .aw-epCreateObject-elementMargin {
        margin: $panel_section_title_padding $margin_small;
    }

    .aw-epCreateObject-addProjectHeaderTitle {
        font-size: $fontsize_2x_large;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        display: inherit;
    }

    .aw-epCreateObject-createObjectHeaderMessage {
        font-weight: $font_weight_400;
        font-style: italic;
        font-size: $fontsize_large;
        overflow: visible;
        width: auto;
    }

    .aw-epCreateObject-TypeSelection {
        font-weight: 600;
        font-size: $fontsize_2x_large;
    }

    .aw-epCreateObject-panelFooter {
        padding-top: rem-calc(32);
    }
}
