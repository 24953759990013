/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2022.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-working-hours,
.aw-non-working-hours {
    font-weight: 480;
    margin: rem-calc(1);
    padding: rem-calc(7);
}

.sw-property.sw-component.aw-detailsOfDay-label {
    .sw-property-name {
        max-width: fit-content;
        margin: 0 rem-calc(58) 0 $padding_xsmall;
    }
}

.aw-startWeek-label.sw-property.sw-row {
    .sw-property-name {
        max-width: fit-content;
    }
}

.aw-calenderMgmt-datePicker {
    min-width: fit-content;
}

.aw-datePicker-div{
    display: inline;
}


.aw-datePicker-Exception,
.aw-datePicker-nonworking,
.aw-datePicker-selectedDay,
.aw-datePicker-inheritedException {
    height: rem-calc(15);
    width: rem-calc(15);
    border-radius: 50%;
    display: inline-block;
}

