/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-updateFlows-Indicator{
    min-width: rem-calc(15);
    margin: auto 0;
    padding-bottom: rem-calc(2);
}




