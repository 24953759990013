// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/


/*========================== EP Structure Configuration =========================*/


/*=========================================================================*/

.aw-epStructureConfiguration-bar {
    &.aw-advanced-bar.aw-advanced-bar-horizontal {
        min-height: unset;
        flex-shrink: 0;
    }
}

.aw-epStructureConfiguration-filter {
    .aw-base-blk-button {
        height: rem-calc(32);
    }
}

.aw-epStructureConfiguration-checkBoxMargin {
    margin: $margin_large 0 0 $margin_normal;
}

.aw-epStructureConfiguration-showExcludedFlag {
    display: inline-flex;
    align-items: flex-end;
    height: 0;
}

.aw-epStructureConfiguration-filter{
    .aw-layout-panelMain{
        .aw-layout-panelContent {
            .aw-layout-panelFooter {
                padding: rem-calc(32) $padding_xlarge $padding_xlarge $padding_xlarge;
            }
        }
    }
}
/*========================================================================*/
/*=================== MFE Configuration Chip ========================*/
/*========================================================================*/
.aw-epChip-container{
    height: rem-calc(24);
    font-size: $fontsize_normal;
    line-height: rem-calc(24);
    border-radius: rem-calc(2);
    margin: $margin_small $margin_normal $margin_small 0;
    padding: 0 $padding_normal;
}

.aw-epChip-label{
    font-weight: $tab-font-weight;
}
.aw-epChip-icon {
    height: rem-calc(16);
    width:rem-calc(16);
    margin-top: rem-calc(3);
}
