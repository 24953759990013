// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


.aw-ep-consumptionDetailsText{
    color: $brand_tertiary_accent_background;
}

.aw-ep-assignemntIndicationTooltipPopup{
    .aw-layout-popup{
        border-color:$brand_primary_background;
    }
}

.aw-ep-inScopeTitle, .aw-ep-outOfScopeTitle{
    color:$brand_secondary_content_text;
}

.aw-ep-assignmentIndicationCount {
    .aw-mfeChip-container {
        border-color: $Siemens_Status_Red;
        background: $Siemens_Snow;
        color: $Siemens_Status_Red;
    }
}
