/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2021.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-epStructureSearch-body {
    background-color: $brand_tertiary_content_background;
}
