/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2022.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-tcmaClassification-header {
    .aw-layout-defaultHeader {
        height: rem-calc(75);
        flex: none;

        .aw-layout-header {
            padding-top: $padding_small;
            padding-bottom: $padding_small;
        }
    }
}
