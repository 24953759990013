#ModOrgTreeScroll{
    height: 70vh;
}
.g4b_searchbuttonclass2 #g4b-vaasDivElement {
    position: fixed;
    background: #ffffff;
    bottom: 0;
    z-index: 2;
}

.g4b-vaaSElementMinimized {
    position: fixed;
    top: calc(100% - 24px);
    width: 300px;
    right: 55px;
}

.g4b-vaasToggleMinimize {
    height: 24px;
    width: 24px;
    right: 5px;
    top: 1px;
    position: absolute;
    transform: rotate(45deg);
    cursor: pointer;
}

.g4b-vaasToggleMaximize {
    height: 24px;
    width: 24px;
    right: 5px;
    top: 1px;
    position: absolute;
    transform: rotate(225deg);
    cursor: pointer;
}

.g4b-vaasFullScreen {
    height: 24px;
    width: 24px;
    right: 5px;
    top: 1px;
    position: absolute;
    transform: rotate(225deg);
    cursor: pointer;
}

.g4b-vaasHeader {
    background-color: #55a0b9;
    color: #ffffff;
    font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
    font-size: "14px";
    font-weight: bolder;
    padding: 5px 10px;
    cursor: pointer;
}

#g4b-vaasFrame {
    width: 100%;
    height: 100%;
}

/*************START*************/
/* Developer - Dhiraj  */

div.full-screen-outerDiv {
    position: relative;
    display: flex;
    height: calc(92vh - 100px);
    width: 100%;
    border: transparent;
}

div.new-full-screen-outerDiv {
    position: relative;
    display: flex;
    height: calc(89vh - 115px);
    width: 100%;
    border: transparent;
}

div.full-screen-button-groupDiv {
    position: relative;
    height: auto;
    /*width: 25%;*/
    min-width: 150px;
    max-width: 150px;
    border: transparent;
}

.g4b-Message {
    min-height: 100px;
    max-height: 100%;
    width: 100%;
    overflow-wrap: break-word;
}

.g4b-scrollbar {
    overflow: auto;
    width: 100%;
}

div.full-screen-groupDiv {
    position: relative;
    height: 100%;
    width: calc(100% - 400px);
    border: transparent;
}

.full-screen-groupDiv td {
    font-size: .75rem !important;
    padding: 3px !important;
}

.persistent-Summary-Table td,
th {
    font-size: .9rem !important;
    padding: 3px !important;
}

.full-screen-button-groupDiv td {
    padding-top: 10px !important;
}

div.full-screen-summaryDiv {
    position: relative;
    height: auto;
    width: 270px;
    border: transparent;
}


/* Left Column Buttons */

button,
.aw-base-button,
.aw-theme-button,
.aw-theme-taskPanelButton {
    background-color: #afd6e0;
    color: #323232;
    border-color: rgba(31, 78, 121, 1);
    /*rgba(31, 78, 121, 1)      #2882a0   */
}

button,
.gwt-Button,
.aw-base-button {
    cursor: pointer;
    text-align: center;
    height: 32px;
    margin: auto;
    background-color: #afd6e0;
    color: #323232;
    border-color: rgba(31, 78, 121, 1);
    /*rgba(31, 78, 121, 1)      #2882a0   */
}

.validateOuter {
    width: 100%;
    height: 25px;
    display: contents;
}

.validateInnerOne {
    padding-right: 15px;
    width: 5%;
    height: 25px;
}

.validateInnerTwo {
    height: 25px;
    position: relative;
    /* margin-left: 5px; */
}

.g4b-cfg-validateImage {
    margin-left: auto;
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 15px;
}

.btn {
    background-color: #afd6e0;
    color: #323232;
    height: auto;
    border-color: rgba(31, 78, 121, 1);
    /*rgba(31, 78, 121, 1)      #2882a0   */
}

.active,
.button:hover {
    background-color: rgba(31, 78, 121, 1) !important;
    /*rgba(31, 78, 121, 1)      #2882a0   */
    color: rgb(255, 255, 255) !important;
    border-color: #afd6e0;
}

.g4b-button-outer,
.g4b-button-inner {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.g4b-button-outer {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 1% 0 0 1%;
}

.g4b-button-inner {
    width: 99%;
    float: left;
    margin: 0 1% 5% 0;
}

.buttonRowClass {
    background-color: white !important;
}

/* Center Column Group */
.g4b-ui-Group-color .sw-sectionTitleContainer {
   
    background-color: white !important;
}


.g4b-ui-filterCategoryLabel .sw-sectionTitle {
    color: #39a0c0 !important;
    text-transform: capitalize;
    font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
    font-weight: 480;
    font-size: 15px;
}

.g4b-cfg-familyCompletness {
    width: 95%;
    text-align: end;
}

.g4b-filter-align-left {
    /*   float: right;*/
    margin-top: 1px;
    height: 35px;
    margin-bottom: 3px;
    padding: 5px 0;
}

.g4b-filter-align-left-TimeSlicePanel {
    margin-top: 1px;
    height: 25px;
    margin-bottom: 3px;
    padding: 5px 0;

}

.g4b-table-alignment {
    margin-left: 15px;
    margin-right: 20px;
    width: 95%;
    font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
}

.g4b-table-alignment-savevariant {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 3px;
    width: 95%;
    font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
}

.g4b-table-alignment-variantCompare {
    margin-left: 0em;
    margin-right: 20px;
    margin-top: 5.5px;
    width: 95%;
}

.g4b-savedvariant-checkbox {
    position: relative;
    float: right;
}

.g4b-scroll-on-group-column {
    min-height: 400px;
    max-height: 99%;
    width: 100%;
    display: inline-block;
    position: absolute;
    overflow-y: auto;
}

.g4b-scroll-on-saved-column {
    height: 600px;
    display: inline-block;
    overflow-y: auto;
}

.g4b-summary-height {
    height: 100%;
    min-height: 300px;
    width: 97%;
    overflow-y: auto;
}

.subHeaderProperties {
    padding-top: 5px;
    padding-bottom: 5px
}

.subHeaderBackgroundProperties {
    background: #ffffff
}

.g4b-cfg-familyCompletionImage {
    margin-left: 22px;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
}

.g4b-cfg-familyExcludeCompletionImage {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: -4px;
    display: inline-block;
    width: 15px;
}
/* Families of group */

tr th {
    text-align: left;
    border: 0px;
    border-collapse: collapse;
    background-color: #fdfdfd !important;
    padding: 5px;
    height: 15px;
    text-overflow: ellipsis;
}

thead {
    border-bottom: 2px solid grey;
    background-color: #fdfdfd !important;
    border-top: 2px solid grey;
    border-left: 0px;
    border-right: 0px;
}

tbody {
    border-bottom: 1px solid grey;

}

.splBody {
    border-bottom: 0px solid grey;
    border-top: 0px;
}

table,
td {
    border: 0px solid grey;
    border-collapse: collapse;
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
   
}
 tr td{
    font-size: 1rem !important;

}
.g4b-system-selection-icon {
    width: 6%
}

.g4b-violation-icon {
    width: 6%
}


.g4b-packageContent {
    margin-left: 10%;
    width: 100%
}


.g4b-savevariant-height {
    height: 18px;
    padding-top: 8px;
}

.g4b-savevariant-owner {
    width: 30%;
}

.empty-cell {
    width: 2%;
}

.g4b-name-intable-savevariant {
    width: 25%;
}

.g4b-name-intable-savevariant-flag {
    width: 7%;

}

.g4b-name-intable {
    width: 60%;
}

.g4b-name-intable2 {
    width: 50%;
}

.g4b-name-intable-package1 {
    width: 45%;
}

.g4b-name-summary{
    text-align: left !important;
}

.g4b-name-intable-package2 {
    width: 50%;
}

table tr:nth-child(even) {
    background-color: #f1f1f1;
}

.odd {
    background-color: #ffffff !important;
    height: 30px !important;
}

.even {
    background-color: #f1f1f1 !important;
    height: 30px !important;
}

table tr:nth-child(odd) {
    background-color: #ffffff;
}

table tr:hover {
    background-color: #d6e6f7;
}


.svrRowClass {
    background-color: #afd6e0;
    color: #323232 !important;
    border-color: #afd6e0;
}

.selectedSaveVariantRule {
    background-color: #d6e6f7 !important;
    color: #323232 !important;
    border-color: #d6e6f7 !important;
}
.selectedSaveVariantRuleHighlighted {
    background-color: #a2d0ff !important;
    color: #323232 !important;
    border-color: #d6e6f7 !important;
}

.clickableRowPersistentSummary {
    background-color: #afd6e0;
    color: #323232 !important;
    border-color: #afd6e0;
}

.clicked {
    background-color: #d6e6f7 !important;
    color: #323232 !important;
    border-color: #d6e6f7 !important;
}

.bg-white {
    background-color: #ffffff;
}


.g4b-cfg-selectedImage {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    cursor: pointer;
    width: 16px;
    height: 16px
}

.g4b-cfg-indicatorSystemImage {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    width: 16px;
    height: 16px
}

.image-section {
    width: 20px;
}

.summaryGroup {
    color: #4f595d
}

.g4b-system-selection-icon-summary {
    width: 3%;
}

.g4b-ui-filterCategorySearchBoxWidget {
    margin-top: -20px;
    width: 100%;
}

/* Right Column  */

.g4b-cfg-summaryselectedImage {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
}

.g4b-cfg-summaryselectedImageRightColumn {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    width: 11px;
}

.g4b-psummary {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.g4b-footer {
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 100%;
    max-width: 100%;
}

.g4b-summary-desc-width {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 10px);
}

.g4b-summary-name-width {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: max-content;
}

/* Developer - Dhiraj  */
/***********END***************/

/** Ajinkya Start */

.g4b-origin-name {
    font-size: 16px;
    margin-top: 5px;
    margin-left: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
}

.g4b-origin-name-center-column {
    font-size: 18px;
    margin-left: 22px;
    font-weight: 400;
}

.g4b-origin-name-allignment {
    width: auto;
    display: flex;
}

.summary-tab-error-message {
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 5px;
    line-height: normal;
}

.g4b-modify {
    margin-left: 5px;
}

/** Ajinkya End */
/**Pratik**/

.g4b-ui-systemselectionalignment {
    display: block;
}

.g4b-ui-systemselectionoptionvalue {
    margin-left: 20px;
}

.g4b-load {
    width: 100%;
    display: inline-flex;
}

.g4b-loadOne {
    width: 10%;
    height: 25px;
}

.g4b-loadTwo {
    width: 90%;
    margin: 5px;
    font-size: 20px;
}

.g4b-ui-filterCategorySearchBoxWidget {

    margin-top: 20px;
}

.g4b-ui-filterCategorySearchBoxWidget-TimeSlicePanel {
    margin-top: 0px;
}

/** START sarwan-compareSummary*/

.g4b-compare-intable {
    width: 10%;
}

.g4b-compare-system-selection {
    width: 3%;
}

.g4b-compare-name-intable {
    width: 5%;
}

.g4b-compare-summaryselectedImage {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
}

.g4b-panel-icon-image {

    display: inline-flex;
    margin-left: -50px;
    ;

}

.g4b-panel-icon-image-Impac-Analysis {

    display: inline-flex;
    margin-left: 60px;
    ;

}

.g4b-filter-search {
    width: 72% !important;
}

.g4b-compare-td {
    width: 45%;
}

.g4b-compare-green {
    background: #ffffff !important;
}

.g4b-compare-red {
    background: #FFE5B4 !important;
}

.g4b-compare-dummy-td {
    background-color: #ffffff !important;
}

.g4b-compare-grey-td {
    background-color: #f0f0f0 !important;
}

.g4b-compare-text {
    font-size: 14px;
}

.g4b-compare-group-name {
    float: left;
    padding: 0 7px 2px 0;
    font-size: 13px;
}

.g4b-compare-loading {
    text-align: center;
    display: inline-block;
    padding: 0 7px 2px 0;
    font-size: 16px;
    color: #004664;
}

.g4b-compare-loadingIndicator {
    margin-left: 4px;
    margin-right: 4px;
    display: table-cell;
    width: 25px;
}

.g4b-compare-btn {
    width: 55% !important;
    margin: 17px !important;
}

.g4b-custom-variant-tab .aw-base-tabTitle {
    font-size: 13px !important;
}

.g4b-custom-variant-tab .aw-base-tabTitleSelected {
    font-size: 13px !important;
}

.g4b-custom-variant-tab .aw-jswidget-tabBar {
    margin-left: 30px !important;
}

.g4b-display-table {
    display: table;
}

.g4b-align-vertical {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
}

.g4b-compare-btn .aw-base-blk-button {
    background-color: #006487;
    color: #ffffff;
}

.g4b-compare-margin-top {
    margin-top: 10px;
}

.g4b-compare-margin-bottom {
    margin-bottom: 10px;
}

.g4b-compare-margin-top tr {
    background-color: #ffffff !important;
}

.g4b-warning-text {
    color: red;
    font-size: 14px;
}

.g4b-timeslice {
    max-height: 7em;
    overflow: auto;
}

.g4b-timeslice tr {
    background-color: #ffffff !important;
}

.g4b-timeslice tbody {
    border-bottom: 0px;
    border-width: 2px;
    border-style: solid;
    border-color: #daecf0;
}

.g4b-timeslice tr td {
    vertical-align: middle;
    padding-top: 5px;
    padding-bottom: 5px;
}

.g4b-timeslice tr:hover {
    background-color: #d5e6f8 !important;
}

.g4b-timeslice-radio-icon {
    width: 1%
}

.g4b-timeslice .aw-layout-splitterLineHorizontal {
    border-color: #006487;
    border-bottom: 2px solid;
}

.timeslice-effectivity-missing {
    color: #323232;
    font-size: 15px;
}

.sticky {
    position: sticky;
    top: 5px
}

.g4b-hr-color hr {
    border-color: #aaaaaa66;
}

/** END-sarwan-compareSummary*/

.evensubset {
    background-color: #f1f1f1 !important;
}

.evensubset td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 2px !important;
}

.oddsubset {
    background-color: #ffffff !important;
}

.oddsubset td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 2px !important;
}

.tdsubset {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: inherit;
    white-space: normal;
    max-width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 2px !important;
}

.tdsubsetCheckbox {
    width: auto;
    text-align: center;
}

.g4b_searchbuttonclass1 {
    text-transform: uppercase;
    padding-left: 15px;
}

.g4b_searchbuttonclass3 {
    padding-left: 140px;
}

.g4b_searchbuttonclass .aw-base-blk-button {
    background-color: #f0f0f0 !important;
    border-color: #f0f0f0 !important;
    margin-left: 570%;
    width: 40px;
}

.g4b-TSPanel-resetSelectionWrapper {
    width: 100%;
}

.g4b-TSPanel-resetSelectionIconWrapper {
    float: right;
    width: 20%;
}

.g4b-TSPanel-resetSelectionIcon {
    width: 55%;
}

.g4b-TSPanel-resetSelectionTitle {
    float: left;
}

.g4b-radio-button-margin .aw-jswidgets-radioButton {
    margin: 4% !important;
}

.g4b-time-slice-label-padding {
    margin: 10px;
    display: inline-flex;
    white-space: nowrap;
}

.g4b-last-ts-background {
    background: #d4d4d4;
}

.g4b-time-slice-label{
    width: 110px; height: 25px;
}

/* Extended Tooltips */
div.g4b_extendedTooltipText > ul {
    list-style-type: square;
}

.g4b-saved-horizontal-scroll {
    width: 100vh;
    height: 20vh;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
}

.g4b-saved-horizontal-scroll-common{
    width: 100vh;
    height: auto;
    overflow-x: scroll;
    overflow-y: auto;
    white-space: nowrap;
}

.g4b-inline-span {
    display: inline-flex;
}

.g4b-boxzone-recipeterm { 
    cursor:pointer;
}

.g4b-boxzone-recipeterm-selected {
    background: #cde6eb;
}

.g4b-config-update-status {
    display: inline-block !important;
    width: 17em !important;
    text-align: center !important;
}

.g4b-cfsc-help-icon {
    width: 18px;
}

.g4b-cfsc-help-label {
    padding: 0px;
}

.g4b-base-blk-button{
    padding: 8px 8px !important;
    height: 90% !important;
    text-align: center !important;
}

.g4b-popup-label-space-fix .aw-widgets-propertyLabelTopValueContainer {
    flex: 14;
}

.g4b-background-color-change .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel{
    background: #3296b9 !important;
}

.g4b-CustomeFont td {
    font-size: 0.75rem !important;
    vertical-align: top !important;
}

.g4b-unguidedText{
    font-size: 14px;
    text-align: center;
}

.g4b-label-spacing .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel {
    position: relative;
    width: 32px;
    height: 16px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin: -14px 16px -14px -12px;
    border-radius: 15px;
    top: -1px;
    background: #3296b9 !important;
  }

  .g4b_Chip-display{
    flex:none !important;
  }
  
  .g4b-Table-Padding td{
    padding-bottom: 10px !important;
    padding-top:5px !important;
    vertical-align:middle !important;
  }
  .g4b-Table-Padding td.g4b-Text-Box-style{
    padding-bottom: 5px !important;
    padding-top:10px !important;
    vertical-align:middle !important;
    font-size: 14.4px !important
  }


 
 .g4b-custom-widgets-chip .aw-widgets-chip {
    width: 12rem !important;
    padding: 0rem 0.5rem;
    border: 2px solid;
}

.g4b-custom-checkbox .afx-checkbox.afx-checkbox-label-right {
    direction: rtl;
    margin: -3px -10px -10px -10px;
    vertical-align: text-top;
}

.g4b-custom-margin-chipicon .aw-widgets-chip .aw-widgets-chipIcon,
.aw-widgets-chip .aw-widgets-chipUIIcon,
.aw-widgets-chip .aw-widgets-chipCheckmark {
    margin-inline-start: auto;
}

.g4b-custom-margin-label .aw-widgets-chip .aw-widgets-chipIcon + .aw-widgets-chipLabel,
.aw-widgets-chip .aw-widgets-chipLabel + .aw-widgets-chipUIIcon,
.aw-widgets-chip .aw-widgets-chipCheckmark {
    margin-inline-end: auto;
}


.g4b-Table-color table td {
    background-color: #ffffff !important;
}

.g4b-Table-color table th{
    background-color: #f1f1f1 !important;
    font-size: 14.4px !important;
}

.g4b-cancel-button{
    background-color: #ffffff !important;
    border-color: #005f87 !important;
    color: #005f87 !important;
    fill: #005f87 !important;
}

.g4b-Text-Box input{
    height: 30px !important;
    font: inherit !important;
}

.g4b-Text-Box-new input{
    height: 30px !important;
    font: inherit !important;
    border-color:red !important;
}

.g4b-Copy-Buttons{
    gap:45px !important;
}

.g4b-Copy-Buttons button{
    width: 200px !important;
    height:34px !important;
}

.g4b-highlight-Features-cfsc{
    background-color: #FFFFCA !important;  
}

.g4b-time-slice-label-padding {
    margin: 10px;
    display: inline-flex;
    white-space: nowrap;
}

.g4b-add-top-padding {
    padding-top: 15px;
}

.g4b-add-link-style {
    margin-left: -2em;
    padding-top: 14px;
}

.g4b-add-link {
    flex-direction: row !important;
    display: inline-flex;
    white-space: nowrap;
}

.g4b-label-spacing .sw-toggle-val .slider {
    background-color: #3296b9;
}

.g4b-flex-column {
    flex-direction: row !important;
}

.g4b-div {
    font-weight: bold;
    margin-right: -105px;
}

.g4bdiv {
    font-weight: bold !important;
}

.g4b-font-weight {
    font-weight: bold !important;
    width: 97px;
}

.g4b-margin {
    margin-left: -17em;
}

.g4b-margin-addmerge{
    margin-left: -16em;

}

.sw-row .g4b-margin-left {
    flex-direction: inherit !important;
}

.g4b-label-class{
    margin-left: 0.75em;
}

.aw-ui-filterCategoryLabelExpand .sw-button-icon,
.aw-ui-filterCategoryLabelCollapse .sw-button-icon {
    height: 1em;
    width: 1em;
    margin-left: -11.5em;
    margin-bottom: -2em;
}

.g4b_cfsc-button{
   height: auto;
  }

.container {
    display:flex;
    /* items are centered along the line */    justify-content: space-between;
    /* center: items are centered in the cross-axis */
    align-items: center;
    flex:1;
}

.g4bmargingCustom {
    margin-bottom: 20px;
}
.g4b_table_rowmargin{
   text-align: left;
   font-weight: 900;
   font-size:10px;
}

.g4b_filter_align .sw-property-val {
    flex: auto;
    width: 25%;
    display: block;
    word-break: break-word;
}

.g4b_tdata_margin{
    margin-right: 6em;
    margin-left: -16em;
}

.g4b_selection_margin{
    margin-left: -20em !important;
    margin-right: 10em;
   
}

.g4b_divGap_margin{
    margin-right: -15em !important;
}

.g4b-scroll{
    overflow-x: auto;
    height: 100%;
}

.g4b_Violation_Gap {
    margin-left: -5em !important;
    margin-right: 5em;
}

.g4b_featureDisplayText{
    margin-top: 10px;
    font-weight: 480;
    font-size: 15px;
}
.g4b-cfsc2-summary-image {
    display: inline-block;
    width: 16px;
    padding-left: 2px;
    padding-right: 2px;
}

.g4b-cfsc2-svr-name-header {
    width: auto;
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
    color: #006487;
    padding: 1em 
}

.g4b-cfsc2-svr-summary-overview-name {
    font-size: 1rem;
    font-weight: 700;
}
.g4b-cfsc2-svr-variant-icon {
    height: 2.25rem;
    width: 2.25rem;
}