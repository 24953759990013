/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

$font_weight_400:400;

.aw-mbm-mfeHeaderPanel{
    height: rem-calc(40);
}

.aw-mbm-headerBreadcrumbContainer,
.aw-mbm-headerConfigContainer{
    height: rem-calc(30);
}

.aw-mbm-breadcrumbContainer{
    padding-top: rem-calc(5);
}

.aw-mbm-largeHeaderTitle {
    font-size: $fontsize_5x_large;
    font-weight: $font_weight_400;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.aw-mbm-largeHeaderSubTitle {
    font-size: $fontsize_x_large;
    font-weight: $font_weight_400;
}

.aw-mbm-largeHeaderTitleSeparator {
    padding-left: $padding_normal;
    padding-right: $padding_normal;
}

.aw-mbm-toolbarTitle {
    padding-left: 10px;
    font-size: $fontsize_large;
    font-weight: $bold_font_weight;
}

.aw-mbm-italicText {
    font-style: italic;
}

.aw-mbm-clickableCellIcon {
    cursor: pointer;
}

.aw-mbm-changeSummaryTileContainer {
    padding: $padding_large 6px 0 6px;
    height:100%;
    overflow-y: auto;
}

.aw-mbm-changeSummaryContainer {
    padding-bottom: $padding_xlarge;
    overflow: hidden;
    height:100%;
}

.aw-mbm-changeSummaryHeader {
    padding-left: $padding_large;
}

.aw-mbm-changeSummaryHeaderTitle,
.aw-mbm-changeSummaryHeaderSubTitle {
    padding: 0 rem-calc(16) $padding_normal $padding_small;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aw-mbm-focusOnScope{
    padding-left: $padding_small;
    padding-top:$padding_normal;
    .sw-property.sw-row {
        .sw-property-name{
            min-width:20%;
            width: auto;
            font-weight: normal;
        }
    }
    .sw-property.sw-row,
    .sw-lov-container
    {
        margin-right: $margin_normal;
        margin-bottom: 0;
        width:100%
    }
    .base-button{
        height: 32px;
        margin-right: $margin_large;
    }

}
.aw-mbm-changeSummaryHeaderTitle {
    padding-top: $padding_xlarge;
    font-size: $fontsize_large;
    font-weight: $bold_font_weight;
}

.aw-mbm-changeSummaryHeaderSubTitle,
.aw-mbm-noTileMessagePanel {
    font-style: italic;
}

.aw-mbm-alignmentContentWithChangeSummary {
    padding: 0 $padding_xlarge 0 0;
}

.aw-mbm-ebomContent,
.aw-mbm-mbomContent {
    padding: 0 0;
    @extend .sw-row;
    .aw-splm-tableCellContents {
        padding-right:$padding_small;
        padding-left: $padding_small;
    }
    .aw-splm-tableRow .aw-splm-tableCellTop {
        padding-left: $padding_xsmall;
    }
}

.aw-splm-tableCellText.aw-mbm-cellText {
    padding-left: 0;
}

.aw-mbm-changeSummaryHeaderContainer {
    margin-bottom: $margin_xsmall;
    padding-bottom:$padding_normal;
}

.aw-mbm-changeSummaryTileContent {
    padding-left: $padding_small;
    padding-right: $padding_xsmall;
    padding-bottom: $padding_xsmall;
}

.aw-mbm-changeTypeIndicationPanel {
    padding-top: $padding_small;
    padding-bottom: $padding_normal;
}

.aw-mbm-changeTypeIndicationPanel,
.aw-mbm-changeSummarySubTilesHeaderContent,
.aw-mbm-changeSummarySubTileContent {
    padding-right: $padding_normal;
}

.aw-mbm-changeSummaryTileOccPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccPropertyHeaderPanel,
.aw-mbm-changeSummaryTilePropertyPanel,
.aw-mbm-changeSummaryTileOccChildPropertyPanel,
.aw-mbm-changeSummaryTileOccPropertyPanel {
    margin-right: $margin_large;

    .aw-mbm-oldText {
        padding-left: $padding_normal;
    }
}

.aw-mbm-changeSummaryTileHeaderPanel,
.aw-mbm-changeSummaryTileOccChildPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccChildPropertyPanel,
.aw-mbm-changeSummaryTileOccPropertyPanel,
.aw-mbm-changeSummaryTilePropertyPanel,
.aw-mbm-changeSummarySubTileContent {
    padding-left: $padding_xlarge;
}

.aw-type-icon.aw-mbm-changeSummaryTileHeaderIcon {
    width: 24px;
    height: 24px;
    padding-right: $padding_small;
}

.aw-mbm-changeSummaryTileHeaderTitle {
    font-weight: $bold_font_weight;
    padding: 0 $padding_xsmall;
}

.sw-section{
    .sw-row{
        .aw-mbm-changeSummaryTileHeaderTitle,
        .aw-mbm-changeSummaryTileHeaderIcon{
            margin:0;
        }
    }
}

.aw-mbm-changeSummarySubTileContent{
    .sw-row.aw-layout-eachCrumb,
    .sw-row.aw-layout-eachCrumb > span{
         margin:0;
     }
 }

.aw-mbm-changeTypeSeparator,
.aw-mbm-changeTypeHandledSeparator {
    border-left-width: 1px;
    width: 0;
    border-style: solid;
    margin: 1px 0;
}

.aw-mbm-changeSummaryTilePropertyInfo {
    margin-bottom: $margin_small;
}

.aw-mbm-changeSummaryTileOccChildPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccChildPropertyPanel {
    margin-top: $margin_normal;
}

.aw-mbm-changeSummaryTileHeaderPanel,
.aw-mbm-changeSummaryTileOccChildPropertyPanel,
.aw-mbm-changeSummaryTileOccPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccPropertyPanel {
    margin-bottom: $margin_normal;
}

.aw-mbm-changeSummaryTileOccChildPropertyHeaderPanel,
.aw-mbm-changeSummaryTileOccPropertyHeaderPanel {
    font-weight: $bold_font_weight;
}

.aw-mbm-changeSummaryTilePropertyPanel {
    margin-top: $margin_small;
    margin-right: $margin_normal;
    margin-bottom: $margin_small;
}

.aw-mbm-headerPage {
    margin-right: $margin_normal;
    margin-left: 6px;

}

.aw-mbm-changeSummaryTileInfoContent {
    padding-bottom: $padding_xlarge;
}

.aw-mbm-changeSummaryTile {
    position: relative;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    overflow: hidden;
}

.aw-mbm-changeIndicationHandled,
.aw-mbm-changeIndicationHandled .aw-mbm-changeSummaryHandled,
.aw-mbm-changeIndicationAdd,
.aw-mbm-changeIndicationMismatch,
.aw-mbm-changeDisableIndicationMismatch,
.aw-mbm-changeIndicationMissing {
    width: 4px;
    height: 100%;
    position: absolute;
    top:0;
}

.aw-mbm-changeTriangleIndicationAdd,
.aw-mbm-changeTriangleIndicationMismatch,
.aw-mbm-changeTriangleDisableIndicationMismatch,
.aw-mbm-changeTriangleIndicationMissing {
    position: absolute;
    top: 0;
    width: 52px;
    height: 26px;
    transform: rotate(315deg);
    transform-origin: 25% 100%;
}

.aw-mbm-changeIndication {
    position: absolute;
    top: 4px;
    left: 2px;
}

.aw-mbm-changeIndication,
.aw-mbm-changeSummarySubTileIndication {
    width: 17px;
    height: 17px;
}

.aw-mbm-changeSummarySubTileIndication {
    margin-right: $margin_normal;
}

.aw-mbm-changeSummaryTileHeader {
    margin-top: $margin_x_large;
    margin-right: $margin_large;
    margin-bottom: $margin_large;
    margin-left: 28px;
}

.aw-mbm-changeSummaryTileProperty,
.aw-mbm-changeSummarySubTileContent,
.aw-mbm-comparePopupContent {
    padding-top: $padding_normal;
    padding-bottom: $padding_normal;
}

.aw-mbm-changeSummaryTileProperty {
    .aw-widgets-cellListCellTitleBlock {
        .aw-widgets-cellListCellTitle {
            padding-bottom: $padding_small;
        }
    }
}

.aw-mbm-changeSummarySubTilesHeaderContent,
.aw-mbm-changeSummarySubTileContent,
.aw-mbm-changeSummaryTileProperty,
.aw-mbm-comparePopupPropertyContent {
    font-size: $fontsize_normal;

    .aw-widgets-cellListCellTitleBlock {
        .aw-widgets-cellListCellTitle {
            font-size: $fontsize_normal;
        }
    }
}

.aw-mbm-changeSummarySubTileHandled,
.aw-mbm-changeSummarySubTile,
.aw-mbm-changeSummarySubTileSelected {
    cursor: pointer;
}

.aw-mbm-changeSummaryTilesSeparator {
    display: block;
    width: 100%;
    border-bottom: 1px solid;
    clear: both;
}

.aw-mbm-changeSummarySubTilesHeader {
    flex: 0 0 auto;
    padding-top:$padding_xsmall;
    padding-bottom: $padding_xsmall;
    height: rem-calc(34);
}
.aw-mbm-changeSummarySubTilesHeaderContent{
    padding-top:$padding_xsmall;
    padding-bottom: $padding_xsmall;
}
.aw-mbm-changeSummarySubTilesHeaderContent,
.aw-mbm-changeSummarySubTileBreadCrumb {
    padding-left: $padding_normal;
}

.aw-mbm-changeSummarySubTileArrow.aw-jswidget-controlArrow {
    width: 16px;
    height: 18px;
    cursor: default;
    margin-top: 0;
}

.aw-mbm-changeSummarySubTileArrow.aw-jswidget-controlArrow svg {
    margin-left: 0.125rem;
    margin-top: 0.2rem;
}


.aw-mbm-changeSummarySubTileButtonPanel,
.aw-mbm-changeSummarySubTileStatusPanel {
    padding-top: $padding_normal;
}

.aw-mbm-bold-text {
    font-weight: $bold_font_weight;
}

.aw-mbm-showOpenWorkpackagePanel {
    padding: $padding_large;
}

.aw-mbm-cnTableWorkpackageCell {
    padding-left: 5px;
}

.aw-mbm-comparePopupPropertyContent .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellTitle {
    padding-bottom: $padding_small;
}

.aw-mbm-comparePopupHeaderTitle {
    font-style: italic;
    margin: $margin_large $margin_large $margin_large 0;
}
.aw-mbm-removeScopeListText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sw-popup{
    .aw-mbm-removeScopePopupContent{
        .aw-widgets-cellListWidget{
            .aw-widgets-cellListItem{
                padding-left:0;
                padding-right: 0;

                .sw-property.sw-row{
                    margin-bottom: 0 ;
                }
            }
        }
    }
}

.sw-button-group.aw-mbm-comparePopupButtonPanel,
.sw-button-group.aw-mbm-showOpenWorkpackageControlPanel{
    margin-bottom: $margin_large;
    margin-right: $margin_large;
}

.aw-mbm-comparePopupPropertyInfoSubHeader {
    padding: $padding_xlarge;
}

.aw-mbm-comparePopupPropertyContent {
    margin-left: $margin_large;
    margin-right: $margin_large;
}

.aw-mbm-comparePopupPropertyInfo {
    padding-bottom: $padding_xlarge;
}
.aw-mbm-comparePopupValue{
    padding-right: $padding_small;
}
.aw-mbm-gridContent {
    @extend .sw-column;
    .aw-splm-table {
        @extend .h-12
    }
}
.aw-mbm-hideEbomGridContent,
.aw-mbm-hideMbomGridContent {
    display:none;
}
.aw-mbm-createMCNPopup {
    .sw-sectionTitle {
        display: none;
    }
}

.aw-mbm-mfgRepPopup {
    margin: $margin_large;
}

.aw-mbm-mfgRepPopupContent {
    margin-top: $margin_small;
    margin-bottom: $margin_small;
    padding-top: $padding_xsmall;
    max-height:  rem-calc(250);
}

.aw-mbm-animationRight {
    position: relative;
    animation-name: animateright;
    animation-duration: 0.2s;
}

@keyframes animateright {
    from {
        right: -300px;
    }

    to {
        right: 0;
    }
}

.aw-mfe-focusTooltipDescription {
    margin-top: $margin_normal;
}

.aw-mbm-removeScopePopup {
    .aw-layout-panelBody {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .aw-widgets-cellListWidget {
            .aw-widgets-cellListItem {
                .aw-widgets-cellListItemCell {
                    width: 100%;
                }
            }
        }
        .aw-widgets-modelObjectList{
            margin-bottom: 0;
        }
    }
}

.aw-mbm-removeScopeIcon {
    .aw-base-icon {
        width: 16px;
        height: 16px;
        padding-left: 0;
        margin-right: $margin_small;
    }
}

.aw-mfe-scopeData {
    padding-top: $padding_small;
    list-style: inherit;
    padding-left: 15px;
    margin: inherit;
}

.aw-mfe-scopeDataElements {
    padding-top: $padding_xsmall;
}

@keyframes tileinstance-fadein{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@-webkit-keyframes tileinstance-fadein{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.aw-mbm-isstanceTile-anim {
    -webkit-animation-name: tileinstance-fadein;
    animation-name: tileinstance-fadein;
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.aw-mbm-tileInstanceDisable {
    opacity: .6;
    cursor: default;
}

.aw-mbm-focusSection{
    padding-top: $padding_small;
}

.aw-mbm-outFocusSection,
.aw-mbm-noAssignmentScopeSection {
    padding-top: 10px;
}

.aw-mbm-noAssignmentScopeSection,
.aw-mbm-focusSection,
.aw-mbm-outFocusSection,
.aw-mbm-noAssignmentScopeSection {
    .aw-panelSection {
        margin-top: 0;
        margin-bottom: 0;
        padding : 0;
    }

    .sw-section-content {
        padding: $padding_small 0 0 0;
        > .sw-flex-column {
            margin-bottom: 0;
        }
    }

    .sw-sectionTitle {
        text-transform: initial;
    }
}

.aw-mbm-noTileMessagePanel{
    padding:20px 24px 14px 24px;
    text-align: center;
}


.aw-mbm-comparePopup {
    .aw-layout-panelTitle {
        padding-left: $padding_xlarge;
        padding-right: $padding_xlarge;
    }
}

.aw-mbm-mismatchActionPopup{
    position: relative;
    margin: $margin_large;
    .aw-layout-panelContent{
        padding: 0;
    }
    .aw-layout-popup .aw-widgets-cellListWidget .aw-widgets-cellListItem{
        width:100%;
    }
}

.aw-mbm-displayLabel {
    font-size: $header_fontsize;
    padding-right: $padding_normal;
}

.aw-mbm-showOpenWorkpackagePanelHeader{
    margin-bottom: $margin_large;
}
.aw-mbm-showOpenWorkpackagePaneOption{
    margin-left: $margin_x_large;
    margin-right: $margin_x_large;
    margin-bottom: $margin_normal;
}
.sw-button-group.aw-mbm-showOpenWorkpackageControlPanel{
    margin-top: $margin_normal;
}

.aw-mbm-mfgImpactPopup{
    margin: $margin_x_large;
}

.aw-mbm-mfgImpactPopupHeader{
    margin-bottom: $margin_large;
}

.aw-mbm-mfgImpactPopup{
    .aw-splm-table{
        @extend .h-12;
    }
}

.aw-mbm-taskBartSeparator{
    margin-left: $margin_large;
}
.aw-mbm-cnIndicationToolbar {
    width: rem-calc(24);
    height: rem-calc(24);
    margin-right:$margin_normal;
}
.aw-mbm-cnIndicationPanel{
    margin-left: $margin_large;
}

.aw-mbm-GuidanceMsg {
    margin-left: $margin_normal;
    margin-top: $margin_normal;
    .guidance-container-type-information{
        height: rem-calc(33);
    }
}

.aw-mbm-noVendor  {
    margin-top: $margin_large;
    font-size: $fontsize_large;
    font-weight: normal;
    font-style: italic;
    opacity: 0.5;
    .guidance-container-banner {
      border: none;
    }
}
