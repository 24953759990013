.g4b-tako-compare-th {
    text-align: center;
}

.g4b-warning-text-TaKo {
    color: black;
    font-size: 13px;
    font-weight: bold;
}
.g4b-tako-ppg-checkbox  {
     max-height: 16px;
}

.g4b-tako-ppg-checkbox label {
    padding-right: 10px;
    white-space: nowrap;
    margin-top: 3px;
}

.g4b-tako-ppg-checkbox input {
    vertical-align: middle;
    max-width: 20%;
}

.g4b-tako-ppg-checkbox label span {
    vertical-align: middle;
}
.g4b-tako-ppg-stylesheet label {
    display: inline-block;
    margin-top: 8px;
    font-weight:500;
}

.g4b-tako-ppg-list{
    max-height: 300px;
    display: grid;
}

.g4b-tako-ppg-stylesheet input {
    vertical-align: middle;
    max-width: 50%;
    border: 1px solid #000; 
}

.g4b-tako-ppg-stylesheet label span {
    vertical-align: middle;
    text-align: center;
}

.g4b-tako-ppg-stylesheet div {
    font-size: 14px;
    text-align: center;
    width: 150px;
}

/**Tako Group*/
.g4b-tako-searchBox {
    width: 283px;
    max-width: 300px;
}

.ppgcheckbox{
    float: left
}

.g4b-tako-filterSearchBoxWidget{
    margin-left: 0px; 
    margin-right: 5px;
} 

.g4b-tako-filteSearchBoxWrapper{
    width: 100%;
    height: 30px;
    margin-bottom: 0px; 
    padding: 5px 0;
}

table.g4b-tako-ppglist-table tr:nth-child(even){
    background-color: #bed3ff;
    border: 1px solid white;
    
    
}
table.g4b-tako-ppglist-table tr:nth-child(odd){
    background-color: #d4e5ff;
    border: 1px solid white;
    
}

table.g4b-tako-ppglist-basetableTitle,
td.g4b-tako-ppglist-baseTitle{
    background-color: #b2c9fadc;
    border: 1px solid white;
    width: 100%;
}
table.g4b-tako-ppglist-overlaytableTitle,
td.g4b-tako-ppglist-baseOverlayTitle{
    background-color: #b2c9fadc;
    border: 1px solid white;
    width: 14%;
}
table.g4b-tako-ppglist-overlaytableTitle,
td.g4b-tako-ppglist-overlayTitle{
    background-color: #7aa3fcc9;
    border: 1px solid white;
    width: 100%;
}

table.g4b-tako-ppglist-table,
td.g4b-tako-ppglist-base{
    background-color: #b2c9fadc;
    border: 1px solid white;
    width: 14%;
    
}
table.g4b-tako-ppglist-table,
td.g4b-tako-ppglist-overlay{
    background-color: #7aa3fcc9;
    border: 1px solid white;
    width: 14%;
    
}
.g4b-tako-ppglist-values{
    font-size: 19px;
    padding-bottom: 3px;
}

.alignprototypeScroll{ 
    max-height:400px;
    overflow-y: scroll;
}

.tako-proplabel{
    float: left;
    text-align: right; 
    width: 40%;
}
.tako-propValue{
    float: right;
    width: 60%;
    text-align: inherit;
}
.tako-label-display{
    display: flex;
}

.tako-email-proplabel {
    float: left;
    text-align: right; 
    width: 30%;
}

.g4b-info-text-TaKo {
    color: red;
    font-size: 13px;
    font-weight: bold;
}

.g4b-Tako-PPG-input textarea{
    height: 110px;
}

.g4b-PPG-Note {
    color: #FF0000;
    font-size: 14px;
    font-weight: 600;
}

.g4b-create-prototype-half-button {
    text-align: center;
}
.g4b-create-prototype-half-button .aw-base-blk-button {
    width: 50%;
}

.prototypeWorksetScroll{ 
    max-height: 350px;
    overflow-y: scroll;
}
.g4b_calculateCommand{
    text-align: -webkit-right;
}

.g4b-cfg-ImpactAnalysisImage{
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
    vertical-align: text-bottom;
}

.g4b-addPPGTable{
    text-align: justify;
    border-collapse: unset;
    border: hidden;
}

.g4b-addPPG-tr{
    background-color: white !important;
    border: hidden;
}

.g4b-addPPG-td{
     padding-left: 0px;
     padding-right: 0px; 
     padding-top: 0px;
     padding-bottom: 0px;
     vertical-align: middle;
}

.g4b-addPPG-td-Image{
    padding-left: 0px;
     padding-right: 0px; 
     padding-top: 0px;
     padding-bottom: 0px;
}

.g4b-cfg-tbody{
    border: hidden;
}

.g4b-adPPG-Icon{
    float: left
}

.g4b-addPPG-inputBox{
    float: left;  
    margin-top: inherit; 
    width: min-content;
}