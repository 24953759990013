// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-lineLayout-associatedElementCommandsPanel {
    border-color: $brand_secondary_border;

    &:hover {
        background-color: $brand_selection_background_hover;
    }

    &:active {
        background-color: $brand_selection_background_active;
    }
}

.aw-lineLayout-disabled {
    background-color: $brand_secondary_content_background;
    border-color: $brand_primary_border_disabled;
    color: $brand_primary_border_disabled;

    &:hover {
        background-color: $brand_secondary_content_background;
    }
}
