/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2022.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.ep-planning-operationsHeader{
    background-color: $aw_gray_25;
}

.ep-operationPlanning-leftTaskBar {

    .aw-mfeChip-container {
        color: $PL_Black_6;
        background-color: $aw_gray_25;
    }
}

