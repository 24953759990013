// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-epBalancingComparePanel-heading {
    background-color: $PL_Black_25;
    color: $PL_Black_4;
}

.aw-epBalancingComparePanel-multipleOverrideWarning {
    $multipleWarningColor: $PL_Black_8;

    .aw-epBalancingComparePanel-multipleOverrideWarningText {
        color: $multipleWarningColor;
    }

    .aw-epBalancingComparePanel-multipleOverrideWarningIcon {
        svg * {
            fill: $multipleWarningColor;
        }
    }
}
