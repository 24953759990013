/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2014.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Siemens default styling */

.aw-occmgmt4gf-effectivityEndDate {

    margin-top: -15px;
}

.aw-occmgmt4gfjs-ruleDate .aw-layout-flexbox {
    padding-left: 18px;
    margin-bottom: 15px;
}

.aw-command-panelContainer[panel-id="Fgf0CreatePartitionSchemePanel"] .aw-layout-panelMain .aw-layout-panelSection[caption="i18n.objectType"] {
    margin-bottom: -24px;
}

.aw-occmgmt4gf-popupScrollPanel {
    height: 32px;
}

.aw-4gf-mainScrollPanel {
    max-height: 400px;
    max-width: 300px;
    min-width: 200px;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
}

.aw-4gf-popupScrollPanel {
    max-height: 300px;
    width: 300px;
    word-wrap: break-word;
}

.aw-subset-proximitySection
{
    margin-top: 5px;
}

.aw-subset-subsetRecipe
 {
    padding-top: 10px;
    padding-bottom: 15px;
}

.aw-subset-subsetRecipe.aw-subset-discoveryRecipe
 {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -8px;
}

.aw-subset-subsetRecipeGroup
{
    padding-top: 2px;
    padding-left: 8px;
}

.aw-subset-subsetRecipeContainer{
    padding-bottom: 2px;
}

.aw-subset-recipeLogicText{
    padding: 0;
    float: left;
    margin-right: 2px;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-size: $fontsize_normal;
    margin-top: 2px;
    margin-left: 12px;
    height: 20px;
}

.aw-subset-recipeLogicText.aw-subset-recipeLogicWithMargin{
    margin-right: 10px;
}

.aw-subset-inSubsetRecipeLabelValueContainer
{
    display: -webkit-flex;
    display: flex;
    width: -webkit-calc(100% - 40px); /* width of container minus button and some padding */
    width: -moz-calc(100% - 40px);
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 18px;
}

.aw-subset-inSubsetRecipeLabelValueContainer.aw-filter-inSmartDiscoveryLabelValueContainer
{
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    width: -webkit-calc(100% - 60px); /* width of container minus button and some padding */
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 20px;
}

.aw-subset-subsetRecipeLabelOnly {
    margin-right: 8px;
}

.aw-subset-subsetRecipeValue, .aw-subset-subsetRecipeLink
{
    @include flex(0 6 auto);
    font-size: $fontsize_normal;
    margin-left: 8px;
    margin-top: 2px;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aw-subset-subsetRecipeLink.aw-filter-recipeLink
{
    @include flex(0 6 auto);
    font-size: $fontsize_normal;
    margin-left: 8px;
    margin-top: 0;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aw-subset-subsetRecipeValue.aw-subset-subsetRecipeValueWithNoMargin, .aw-subset-subsetRecipeLink.aw-subset-subsetRecipeLinkWithNoMargin
{
    margin-top: 0;
}

.aw-subset-subsetProximityRecipeLink .aw-aria-border {
    @include flex(0 6 auto);
    font-size: $fontsize_normal;
    margin-left: 6px;
    margin-top: 2px;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 278px;
    overflow: hidden;
}

.aw-filter-proximityLink .aw-aria-border {
    @include flex(0 6 auto);
    font-size: $fontsize_normal;
    margin-left: 6px;
    margin-top: 2px;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 258px;
    overflow: hidden;
    border: 0
}

.aw-subset-subsetInnerRecipeValue
{
    font-size: $fontsize_normal;
    margin-bottom: 3px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.aw-subset-subsetInnerRecipeValue.aw-filter-expandedRecipeValue
{
    font-size: $fontsize_normal;
    margin-bottom: 3px;
    margin-left: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aw-subset-subsetRecipeLabelValueContainer {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    width: -webkit-calc(100% - 40px); /* width of container minus button and padding for panel margin*/
    width: -moz-calc(100% - 40px);
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 18px;
}

.aw-subset-subsetRecipeLabelValueContainer.aw-filter-discoveryRecipeLabelValueContainer {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    width: -webkit-calc(100% - 60px); /* width of container minus button and padding for panel margin*/
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 20px;
}

.aw-subset-multipleRecipeTermContainer {
    margin-left: 25px;
    margin-top: 2px;
}

.aw-subset-subsetRecipeOperatorImage {
    width: 25%;
    padding-left: 3px;
    padding-right: 5px;
}

.aw-subset-connector{
    margin-top:2px;
}

.aw-commands-commandIconButton.aw-subset-deleteButton{
    padding: 0;
}

.aw-commands-commandIconButton.aw-subset-recipeLogicButton{
    padding: 0;
    float: left;
}

.aw-subset-recipeLogicButton.aw-subset-recipeLogicHidden{
    float: left;
    visibility:hidden;
}

.aw-subset-settingsButton {
    height: 18px;
    width: 18px;
    display: inline-block;
    cursor: pointer;
    border: none;

}

button.aw-subset-settingsButton.aw-base-iconButton {
    padding: 0;
    margin-right: 5px;
    margin-top: 15px;
    margin-left: 20px;
}

.aw-subset-settingsText {
    margin-top: 15px;
}

.aw-subset-recipeSeparator{
    margin-top: $margin_normal;
    margin-bottom: $margin_normal
}

.aw-filter-categoryLogicIcon {
    height: 16px;
    width: 16px;
    display: inline-block;
    cursor: pointer;
    border: none;
    float: right;
    margin-right: 5px;
    margin-top: 7px;
    margin-left: 5px;
}

.aw-filter-discoveryCategory:first-child {
    margin-top: 5px;
}

.aw-filter-discoveryCategory
{
    margin-top: 20px;
}

.aw-filter-categoryLogicIconWrapper {
    height: 26px;
    width: 26px;
}

.aw-filter-boxZoneCheckBox {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.aw-filter-discoveryFilterProperty {

    .aw-ui-filterInFiltersSearchBox {
        padding: 0 8px 16px 8px;
    }

}

.aw-subset-subsetRecipeLabel
{
    @include flex(0 1 auto);
    font-size: $fontsize_normal;
    margin-left: 8px;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

aw-include[name="FgfSubset"] .aw-ui-categoryWrapper {
    .propertyLabelTopContainer {
        .aw-widgets-propertyLabelTop {
            visibility: hidden;
        }
    }
}


