// Copyright 2020 Siemens Product Lifecycle Management Software Inc.

// mixins
.aw-clsadmin-objects .aw-widgets-chip:not(.disabled).aw-negative-button:not(.aw-widgets-staticChip):hover {
    background-color:transparent;
}

.aw-clsadmin-objects .aw-widgets-chip:not(.disabled).aw-negative-button {
    background-color:transparent;
}

