// Copyright 2022 Siemens Product Lifecycle Management Software Inc.

.aw-csi-objectsRelatedToChange aw-splm-table .aw-splm-table{
    height: 100%;
}

.aw-csi-objectsRelatedToChange .aw-splm-tableCanvas.aw-splm-tableViewport{
    height: 100%;
}

.aw-csi-schedule-task-related-objects{
    padding-top: rem-calc(20);
}

.aw-csi-button-padding{
    padding-top: rem-calc(10);
}

.aw-csi-table-margin {
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
    margin-bottom: rem-calc(10);
}