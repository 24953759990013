// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== EP work instructions =========================*/
/*=========================================================================*/

.aw-ep-missingInSourcePanel {
    border-top-color : $Siemens_Status_Red;
}
