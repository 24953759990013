/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2018.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

aw-aps-effectivity-intent .aw-layout-flexRowContainer .aw-base-normal a {
    line-height: 1;
    padding-left: 2px;
}

.aw-aps-effectivityIntentContainer {
    padding-left: 20px;
}
