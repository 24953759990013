// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


.aw-ep-highLevelPlanningGuidanceMsg{
    .aw-guidance-message .aw-guidance-guideText-hyphen, .aw-guidance-messageText{
        color: $brand_primary_background;
    }
}

