// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-ep-treeTable {
    .aw-jswidgets-grid .aw-splm-tableRow.ui-grid-row {

        &.aw-epDragDrop-resequenceTop {
            border-top: rem-calc(2) solid;
        }

        &.aw-epDragDrop-resequenceBottom {
            border-bottom: rem-calc(2) solid;
            margin-bottom: rem-calc(1);
        }
    }
}
