.dialog_box {
    height: 94vh !important;
    overflow: auto !important;
}


.search_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.search_checkbox {
    margin-right: 46px !important;
    height: 32px;
}

.label_button {
    width: 100% !important;
}
.vim-bmw-action-section .sw-button {
    width: 100%;
    height: auto;
}

.signoffPerformSecondary .sw-button {
    width: 100% !important;
    height: auto;
    margin-top: 10px;  
}
.signoffPerformSecondary .sw-button:not(:last-child) {
    margin: 0 !important;
}
