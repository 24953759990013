// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== EWI =========================*/
/*=========================================================================*/


.sw-ewi-taskbarButton {
    margin: $popup_button_not_last_child_margin;
}

.ewi-showObjectHeader {
    .aw-search-globalSearchWidgetContainer {
        margin-left: rem-calc(32);
    }

    .aw-layout-headerContribution {
        width: $listbox_cell_list_item_label_width;
    }

    .aw-ewi-headerWrapper::-webkit-scrollbar {
        height: rem-calc(6);
    }

    .aw-ewi-headerWrapper::-webkit-scrollbar-thumb {
        border-width: $input_border_width;
    }

    .aw-layout-headerProperty {
        width: fit-content;
        white-space: nowrap;
    }

    .aw-layout-headerContribution {
        .sw-row {
            overflow: unset;
            width:100%;
        }
    }
    .sw-wo-overflow {
        flex: none;
        flex-wrap: nowrap;
        width:100%
    }

    .aw-ewi-headerWrapper {
        overflow-x: auto;
        display: flex;
        overflow-y: hidden;

        .sw-row {
            overflow: unset;

            span br {
                display: none;
            }
        }
    }
}

.aw-ewi-subpageContent {
    padding: $padding_normal;
    .aw-sidenav-push.aw-sidenav-rightBackground {
        box-shadow: none;
    }
}

.aw-workinstr-universalViewerContainer {
    display: flex;
    height: 100%;
    .aw-viewerjs-dimensions {
        height: 100%;
    }
}

.aw-workinstr-galleryViewer {
    display: flex;
    align-items: center
}

.aw-ewi-displayProperty {
    &.sw-component {
        display: block;
    }
    .sw-property-name,
    .sw-property-val {
        display: block;
        white-space: nowrap;
        overflow: unset;
        margin: $margin_small;
        width: auto;
        min-width: fit-content;
    }
}
