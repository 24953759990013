// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*========================================================================*/
/*=========================== MFE Pert ==========================*/
/*========================================================================*/
.aw-mfePert-settingsBar {
    .aw-advanced-bar-horizontal {
        box-shadow: none;
        margin: 0;
        button {
            padding: $padding_small;
            margin: 0;
        }
    }
    .aw-base-iconButton.large {
        height: rem-calc(24);
        width: rem-calc(24);
    }
}

.aw-mfePert-border {
    border: $default_border;
}

.aw-layout-popup.aw-mfePert-togglePopupMargin{
    margin-left: $margin_large;
    margin-top: $margin_large + $margin_xsmall;
    min-width: fit-content;
    border: $default_border;

    .sw-popup-contentContainer{
        overflow: hidden;
    }
}

.aw-mfePert-toggleModePopup{
    z-index: 99999;
}
