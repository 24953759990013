.VisPanel .aw-layout-panelBody {
  height: calc(100vh - 350px) !important;
}


.VisPanel .aw-panelBody {
  height: calc(100vh - 350px) !important;
}

.VisPanelFTMStruct .aw-panelBody {
  height: calc(100vh - 220px) !important;
}

.button {
  display: block;
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  /*display: inline-block;*/
  font-size: 16px;
  /*margin: 4px 2px;*/
  transition-duration: 0.4s;
  /*cursor: pointer;*/
  /*position:absolute;*/
  z-index: 3;
}

.positionCenterTop {
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
}

.positionCenterTopExtraWidth {
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}

.positionCenter {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 369px;
}

.buttonL {
  border-radius: 12px 0 0 12px;
  width: 70%;
  height: 50px;
}
.buttonC {
  border-radius: 0 0 0 0;
  width: 15%;
  height: 50px;
}
.buttonR {
  border-radius: 0 12px 12px 0;
  width: 15%;
  height: 50px;
}

.buttonLExHt {
  border-radius: 12px 0 0 12px;
  width: 70%;
  height: 75px;
}
.buttonCExHt {
  border-radius: 0 0 0 0;
  width: 15%;
  height: 75px;
}
.buttonRExHt {
  border-radius: 0 12px 12px 0;
  width: 15%;
  height: 75px;
}

.btn-group {
  position: absolute;
  /* white-space: nowrap; */
  z-index: 4;
}

.btn-group button {
  float: left;
  padding: 0px 12px;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
}

/*.btn-group button:not(:last-child)
{
    border-right: none; // Prevent double borders
} 
*/

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

.btn-message {
  background-color: #0077a1;
  font-size: 24px;
  position: absolute;
  border-radius: 12px;
  padding: 8px 16px;
  width: 225px;
  height: 50px;
}

/* Info Button */
.btn-info {
  background-color: #0077a1;
  position: absolute;
  border-radius: 12px;
  /* padding: 8px 16px; */
  width: 102px;
  height: 38px;
  bottom: 9px;
  font-size: 14px;
}

.btn-grp-info {
  background-color: #0077a1;
}
.btn-grp-message {
  color: #ffb900;
}
.btn-grp-confirm {
  background-color: #0077a1;
  color: white;
  cursor: pointer;
}
.btn-grp-confirm:hover {
  background-color: #5e8e9e;
  color: #ffb900;
}
.btn-grp-cancel {
  background-color: #6b767a;
  color: white;
  cursor: pointer;
}
.btn-grp-cancel:hover {
  background-color: #7a9199;
  color: #ffb900;
}

/* Dropdown Button */
.dropbtn {
  border: none;
  color: white;
  padding: 9px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  right: 18px;
  margin-top: 18px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  background-color: #0077a1;
  z-index: 3;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3f849b;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  right: 81px;
  margin-top: 27px;
  display: inline-block;
  z-index: 4;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: block;
  background-color: #f1f1f1;
  border-radius: 5px;
  min-width: 54px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 5px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

/* Pulsing Button */
@keyframes glowing {
  0% {
    background-color: #0077a1;
  }
  50% {
    background-color: #0077a1;
    color: #ffb900;
  }
  100% {
    background-color: #0077a1;
  }
}

.animation {
  animation: glowing 2700ms infinite;
}
/* hide unwated command in ftmPreviewImagePanel*/
#ftmPreviewImagePanel button#Awp0MarkupHighlight,
#ftmPreviewImagePanel button#Awp0MarkupGdnt,
#ftmPreviewImagePanel button#uvCancelCheckout,
#ftmPreviewImagePanel button#uvCheckin,
#ftmPreviewImagePanel button#rotateCCW,
#ftmPreviewImagePanel button#rotateCW,
#ftmPreviewImagePanel button#fullViewMode,
#ftmPreviewImagePanel button#uvCheckout,
#ftmPreviewImagePanel button#Awp0ExitFullScreen {
  display: none;
}
/* hide OOTB viewer command bar*/
#ftmPreviewImagePanel #aw-image-viewer div.aw-viewerjs-header {
  display: none;
}

.aw-viewerjs-fullViewActiveBody #ftmAwImageViewerPanel {
  min-height: 400px;
  height: -webkit-calc(100vh - 140px) !important;
  height: -moz-calc(100vh - 140px) !important;
  height: calc(100vh - 140px) !important;
}

#ftmAwImageViewerPanel {
  height: 400px;
  min-height: 400px;
}
