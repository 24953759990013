// Copyright 2020 Siemens Product Lifecycle Management Software Inc.
.aw-activecollab-shadow .aw-widgets-cellListItem {
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    opacity: 1;
    margin: 5px 0;
    padding-right: 4px;
}

.aw-activecollab-feedShadow{
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    opacity: 1;
    margin: 5px 0;
    padding-right: 4px;
    padding: 6px;
}

ul.aw-widgets-cellListWidget .aw-activecollab-nonIndentedList ul {
    margin-left: 0;
}

ul.aw-widgets-cellListWidget .aw-activecollab-nonIndentedList .aw-widgets-cellListItem {
    padding: 2px;
}

.aw-activecollab-convCreds {
    font-size: $fontsize_normal;
}

.aw-activecollab-statusLabel {
    padding: 2px;
}

.aw-icon-padding {
    padding-right: 12px;
}

.aw-activecollab-userBold {
    font-weight: bold;
}

.aw-activecollab-autoMsg {
    font-style: italic;
    margin-left: 2px;
}

/* for ckeditor */
.aw-activecollab-create.aw-activecollab-editor {
    padding: 0;
    margin-top: 5px;
}

.aw-activecollab-create.aw-activecollab-editor .ck-editor__editable_inline {
    min-height: rem-calc(300);
    max-height: rem-calc(400) ;
    border: 1px solid;
}

.aw-activecollab-quickReply .aw-activecollab-editor {
    padding: 0;
    margin-top: 0;
    margin-bottom: 2px;
    margin-right: 0;
    margin-left: 2px;
}

.aw-activecollab-quickReply .aw-widgets-propertyContainer {
    margin-bottom: 4px;
    margin-left: 2px;
}

.aw-activecollab-quickReply .aw-activecollab-editor .ck-editor__editable_inline {
    min-height: 60px;
    max-height: 120px;
    border: 1px solid;
}

/* separator without excessive margin components */
.aw-activecollab-convSeparator {
    display: block;
    border-bottom: 1px solid;
    margin: 4px 0;
    clear: both;
}

.aw-activecollab-shadow .aw-activecollab-convCellCmd .aw-commands-wrapper {
    padding: 0;
}

.aw-activecollab-srcObjChip .aw-widgets-chip {
    max-width: 70%;
}

.aw-activecollab-srcObjChip.aw-widgets-chip {
    width: fit-content;
}

.aw-activecollab-srcObjChip.aw-widgets-chip.dynamicChip {
    width: fit-content;
}

.aw-commands-svg.aw-activecollab-lockIcon{
    width: 16px;
    height: 16px;
}

.aw-activecollab-chipList .aw-widgets-chip{
    max-width: 90%;
    .aw-widgets-chipLabel{

        max-width: 9rem;

    }
}

.aw-activecollab-chipList.aw-widgets-chipList{
    max-width: 90%;
    align-self: auto;
}

.aw-activecollab-shadow .aw-widgets-cellListItemContainer {
    width: 100%;
}

.aw-activecollab-moreConvCmdsCmdBar.aw-commands-commandBarVertical .aw-commands-wrapper {
    padding: 0;
}

.aw-activecollab-thumbnailIcon svg {
    width: 16px;
    height: 16px;
}


.aw-activecollab-flexColumnContainer {
    align-content: space-evenly;
    padding-left: 2px;
    padding-top: 0;
}

.aw-activecollab-feedFilterPanelClearAll aw-link {
    float: right;
}

.aw-activecollab-editor .ck.ck-content {
    span, p, strong, b {
        font-family: inherit;
    }
}

.aw-activecollab-propertyNonEditValue {
    font-size: $label_font_size;
    font-weight: normal;
    word-wrap: break-word;
}
.aw-activecollab-viewRichText {

    span, p, strong, b {
        font-family: inherit;
    }
}

.aw-base-scrollPanel.aw-activecollab-nonIndentedList {
    height: inherit;
    padding: 0;
}

.aw-base-scrollPanel.aw-panelBody.aw-activecollab-commentListBody {
    height: inherit;
    padding: 0;
}

.aw-activecollab-showCellDecorator {
    border-left: 5px solid transparent;
}

.aw-activecollab-discussionTile {
    width: 100%;
}

.aw-activecollab-snapshotTile {
    max-width: 128px;
    padding: 2px;
    margin: 4px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}

.aw-activecollab-snapshotImg {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    overflow: hidden;
    text-overflow: ellipsis;
}

.aw-activecollab-saveDiscard {
    margin-top: $margin_small;
    margin-left: rem-calc(40);
}
