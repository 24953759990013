/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2022.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-tcma-tab-container {
    .aw-tcma-typeHeader {
        font-size:$fontsize_large;
    }
    .sw-tabContainer {
        margin-left: $margin_normal
    }
    .aw-tcma-showselectedText {
        padding-top: $padding_small;
    }
}

.aw-tcmaMfgSearch-addPartsTable {
    flex-basis: rem-calc(125);
}

.aw-tcmaMfgSearch-addPartsTable .aw-splm-table{
    flex-basis: inherit;
}

.aw-tcmaMfgSearch-addPartsText{
    display: flex;
    height: 100%;
    align-items: center;
    align-content: flex-start;
}

.aw-mfe-tcma-highlightText .aw-splm-tableCellTop .aw-splm-tableCellText{

    font-weight: bold;

}

.aw-tcmaSearch-scrollHeight{
    height: rem-calc(100);
}
