// Copyright 2020 Siemens Product Lifecycle Management Software Inc.
.aw-epMaturity-configurePlanSearchResultArea {
    @include flex(1 1 100%);
    height: 325px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid;
    position: relative;
}

.aw-epMaturity-effectivitySearchResultArea {
    @include flex(1 1 100%);
    height: 149px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid;
    position: relative;
    margin-bottom: 9px;
}

.aw-epMaturity-configurePlanLoadingLabel {
    font-style: italic;
    font-size: $body_font_size_large;
    left: 0;
}
