// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== EP Production Program =========================*/
/*=========================================================================*/

.aw-productVariants-generateLink {

    &:hover {
        background-color: $Siemens_Blue_29;
    }
}

.aw-productVariants-totalRow {
    background-color: $Status_Gray_10;
}
