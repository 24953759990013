// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*=========================================================================*/
/*============================ EP details table ===========================*/
/*=========================================================================*/

.aw-epDetails-fileIcon{
    height: 24px;
    margin-right: 8px;
}

.aw-epFile-preview {
    .aw-viewerjs-commandBarLayout {
        display: none;
    }
    .aw-layout-panelContent {
        padding-right: 0;
    }
}

.aw-epInformation-summary {
    padding: $padding_normal;
    .aw-xrt-tabsContainer{
        display: none;
    }
    .aw-viewerjs-dimensions{
        min-height: rem-calc(260);
    }
    .aw-layout-headerProperties{
        max-height: rem-calc(40);
    }
    .aw-viewerjs-elementPosition {
        align-items: initial;
    }
}

.aw-epDocuments-listSeparator {
    .aw-xrt-separator {
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 0;
    }
}

#epDetailsTable .aw-splm-tableRow,
#epDetailsTable .ui-grid-row {
    margin-bottom: 0;
    border-bottom-width: 1px;
}

.aw-epAttachFilePopup-fileUpload{
    .aw-file-upload-fileName {
        width: calc(100% - 128px);
        margin-bottom: 0;
    }

    .aw-xrt-separator {
        margin-left: 0;
        margin-right: 0;
    }
}

.aw-epDetails-filesList {
    padding-top: 1px;
}
