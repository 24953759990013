// Copyright 2019 Siemens Product Lifecycle Management Software Inc.

.aw-cfg-fscBorder-chartColorUnchanged,
.aw-cfg-fscCharts-chartColorUnchanged {
    border-color: $PL_Black_25; // #f0f0f0
}

.aw-cfg-fscBorder-chartColorModified,
.aw-cfg-fscCharts-chartColorModified {
    border-color: $Siemens_Yellow_11; // #eb780a
}

.aw-cfg-familyHighlightedForCompletenessCheck .aw-layout-collapsiblePanelSectionTitle {
    border-color: $Siemens_Blue_7;
}

.keyboard .aw-cfg-FreeFormIcon.sw-aria-border:focus {
     border-color: $Siemens_Blue_9;
}