/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Schedule Manager default styling */
.aw-schedulemanager-addDeliverable .aw-layout-panelContent {
    height: 100%;
}

/* Schedule Manager Tabs */
.aw-scheduleManager-tabs {
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.aw-schedulemanager-addMember .aw-jswidgets-radioButtonItem {
    display: block;
    padding-top: 5px
}

.sw-image-icon.aw-schedulemanager-resGraphAvailabilityIcon {
    width: 20px;
    height: 20px;
    padding-left: 15px;
}

.aw-schedulemanager-resGraphAvailability .aw-widgets-propertyLabelTopValueContainer {
    min-width: 10%;
}

.aw-schedulemanager-createSchedule-widget {
    margin-top: -5px;
    margin-left: 15px;
    padding: 4px 15px 0 0;
}

/* Create Schedule Panel tabs*/
.aw-schedulemanager-createScheduleTabs {
    padding: 16px 16px 16px;
}

.aw-schedulemanager-searchPanel {
    margin: 0 0 10px 16px;
}

.aw-schedulemanager-searchPanel .aw-search-inContentSearchBox {
    padding-bottom: 0;
    padding-top: 15px;
}

.aw-schedulemanager-searchPanel .aw-widgets-spaceBetween {
    margin-bottom: 3px;
}

.aw-schedulemanager-searchCriteriaList {
    min-width: 150px;
    width: 200px;
}

.aw-scheduleManager-kanban .webix_list_item {
    padding: 5px 5px 5px 5px;
}

.aw-scheduleManager-kanbanBoardCard.webix_kanban_list_item .webix_kanban_tag {
    background: none;
    font-size: $fontsize_normal;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: rem-calc(3);
}

.aw-schedulemanager-kanbanBoardCard.webix_kanban_list_item .webix_kanban_footer {
    padding: 0;
}

.aw-scheduleManager-kanbanBoardCard .webix_kanban_body {
    font-size: $fontsize_large;
    width: rem-calc(56);
    padding-top: rem-calc(5);
}

.aw-scheduleManager-kanbanBoardCard .webix_kanban_footer {
    flex: 1;
    width: rem-calc(50);
    font-family: $font-family;
}

.aw-scheduleManager-kanbanBoardCard .webix_kanban_user_avatarLeft {
    float: left;
    position: initial;
    border-radius: 0;
    width: 24px;
    height: 24px;
    margin-left: $margin_small;

}

.aw-scheduleManager-kanban .webix_kanban_list_item .webix_kanban_footer {
    padding: 0 0;
}

.aw-scheduleManager-kanbanBoardCard {
    .webix_kanban_user_avatarRight {
        visibility: hidden;
    }

    &:hover {
        .webix_kanban_user_avatarRight {
            visibility: visible;
        }
    }
}

.webix_list_item.webix_selected {
    .webix_kanban_user_avatarRight {
        visibility: visible;
    }
}

.aw-scheduleManager-kanbanBoardCard .webix_kanban_user_avatarRight {
    float: right;
    position: initial;
    border-radius: 0;
    width: rem-calc(24);
    height: rem-calc(24);
    background-image: url("image/cmdOpen24.svg");

    .avatar {
        width: rem-calc(16);
        height: rem-calc(16);
    }
}


.aw-scheduleManager-kanbanBoardCard .avatar {
    width: 50px;
    height: 44px;
}

.aw-schedulemanager-splitcontext {
    font-size: $fontsize_normal;
    font-weight: bold;
    padding-block-end: 1%
}

.aw-scheduleManager-kanban {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow-x: scroll;
    width: 100%;
}

.aw-schedulemanager-gantt {
    width: 100%;
}

aw-matrix.aw-schedulemanager-resourceChart .aw-matrix-MainPanel.aw-jswidgets-grid .ui-grid-row,
aw-matrix.aw-schedulemanager-resourceChart .aw-matrix-MainPanel.aw-jswidgets-grid .ui-grid-cell {
    height: 32px;
}

.aw-schedulemanager-resourceChart {
    .aw-matrix-MainPanel .ui-grid-header-viewport {
        overflow: hidden;
        height: 50px;
    }

    .aw-matrix-MainPanel.aw-jswidgets-grid .ui-grid-header-cell:not(:first-child) {
        text-align: left;
        padding-left: 4px;
    }

    .aw-matrix-MainPanel.aw-jswidgets-grid .ui-grid-row .ui-grid-cell:not(:first-child) {
        min-width: 110px;
    }

    .aw-matrix-MainPanel.aw-jswidgets-grid .ui-grid-row .ui-grid-cell:first-child {
        pointer-events: none;
    }

    .aw-jswidgets-grid {
        max-height: 100%;
        min-height: 10px;
    }

    aw-matrix-cell .aw-jswidgets-dataGridCellText {
        height: 32px;
        width: 110px;
        text-align: left;
        align-self: center;
        border: transparent;
    }
}

.aw-schedulemanager-required {
    font-size: $fontsize_large;
    font-weight: normal;
    font-style: italic;
    clear: both;
    float: left;
    width: 100%;
    opacity: 0.5;
}

.aw-resourceLoad-goToCommand {
    .aw-command-bar:not(.aw-layout-userSessionCommands).aw-commands-commandBarHorizontal .aw-commands-showIconLabel .aw-commands-commandIconButton {
        max-width: rem-calc(200);
        width: rem-calc(200);

        .aw-commands-commandIconButtonText {
            margin-left: $margin_small;
            font-size: $fontsize_normal;
        }
    }

    .aw-xrt-separator {
        padding-bottom: 0;
        margin: 0;
    }

    .aw-base-scrollPanel {
        padding: rem-calc(0) rem-calc(5) rem-calc(8) rem-calc(5);
    }

    .aw-resourceLoad-goButton.sw-button {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }
}

.aw-scheduleManager-resourceLoad25,
.aw-scheduleManager-resourceLoad50,
.aw-scheduleManager-resourceLoad75,
.aw-scheduleManager-resourceLoad100,
.aw-scheduleManager-resourceLoad101,
.aw-scheduleManager-resourceLoadWeekend {
    text-align: center;
    height: stretch;
    padding: rem-calc(5);
    margin: rem-calc(1) rem-calc(1) rem-calc(1) rem-calc(1);
    border: solid 1px;
    font-weight: 480;
}

.aw-schedulemanager-searchCriteriaList {
    .propertyLabelTopContainer {
        .aw-widgets-propertyLabelTop {
            visibility: hidden;
        }
    }
}

.aw-scheduleManager-ganttTreeContainer aw-splm-table .aw-splm-table {
    height: 100%;
}

.aw-scheduleManager-ganttTreeContainer .aw-splm-tableCanvas.aw-splm-tableViewport {
    height: 100%;
}

.aw-schedule-navigation-sublocation {
    height: 100%;

    .aw-layout-defaultSublocation.aw-layout-flexColumn {
        height: 100%;
    }

    .aw-layout-primaryWorkarea .aw-layout-workareaCommandbar {
        min-height: unset;
    }
}

.sw-component.aw-schedule-mgr-filter-widgets {
    margin-left: 0;
}

.sw-component.aw-sch-mgr-filter-width-cal {
    width: calc(50% - 0.33rem);
}

.aw-scheduleManager-programView .sw-section,
.aw-scheduleManager-programView .aw-xrtjs-htmlPanelContainer,
.aw-scheduleManager-programView .aw-jswidgets-htmlPanel {
    height: 100%;
}

.aw-scheduleManager-calendar {
    margin-top: rem-calc(-10);
}
