/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-epPert-selectedNode {
    fill: $brand_selection_background_selected;
}

.aw-epPert-selectedNodeBorder {
    stroke: $brand_mid_contrast_button_background_hover;
}

.aw-epPert-node {
    fill: $brand_primary_content_background;
}

.aw-epPert-detailedNode{
    >.aw-epPert-nodeBorder {
        stroke: $brand_secondary_accent_button_border_active;
    }
}

.aw-epPert-highlightedNodeBorder {
    stroke: $brand_primary_border;
}


.aw-epPert-processSideBar {
    fill: $brand_primary_accent_border_changed;
}

.aw-epPert-detailedNode:hover > rect.aw-epPert-node {
    fill: $brand_selection_background_hover;
}

.aw-epPert-detailedNode:hover > rect.aw-epPert-nodeBorder {
    stroke: $brand_mid_contrast_button_background_hover;
}

.aw-epPert-indicationsRectangle{
    fill: $PL_Black_26;
}

.aw-epPert-toolbarBelowTab{
    background-color: $PL_Black_24;
}

.afx-content-background .aw-graph-canvas {
    background-color: $brand_primary_content_background;
}