/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-tcmaStudyManager-header {

    .aw-tcma-headerSection {
        margin-left: $margin_large;
        .aw-layout-column {
            flex-direction: row;
        }

        .sw-property-name {
            min-width: fit-content;
        }

        .sw-property.sw-row {
            margin-bottom: 0;
        }

        .sw-row {
            align-items: center;
        }

        .sw-aria-border {
            margin: $margin_small;
        }

        .sw-component {
            justify-content: center;
            margin: 0;
        }

    }

    .aw-widgets-propertyContainer {
        margin-bottom: 0;
    }

    .aw-widgets-propertyContainer>aw-property-label {
        @include flex(0);
        display: none;
    }

    .aw-seperator-style,
    .aw-tcmaStudyManager-header .aw-widgets-propertyLabelTop {
        display: none;
    }


    .aw-tcma-contextControl {
        .propertyLabelTopContainer {
            margin-left: 16px;
        }

        .aw-layout-column {
            flex-direction: row;
            align-items: center;
        }

        .aw-widgets-linkWithPopupMenu {
            display: inline;
        }

    }
    .aw-studyManager-searchBox {
        width: inherit;

        aw-search-box,
        .aw-search-searchBox {
            width: inherit;

            .aw-uiwidgets-searchBoxContainer {
                height: 30px;
                margin: 0 10px 0 10px;
            }
        }
    }
    .aw-toolbar-layout {
        .aw-toolbar-horizontal {
            box-shadow: none;
        }
    }
}

.aw-tcmaHostedRoot-layout {
    .aw-advanced-bar-horizontal {
        box-shadow: none;
    }
}

.aw-tcmaStudyManager-listContainer {
    padding-left: $margin_large;
    min-width: rem-calc(50);
    flex: 1 1 auto;
}


.aw-tcmaStudyManager-details-container {
    margin-right: $margin_large;
    flex: 1 1 auto;
}

.aw-studyManager-label {
    .sw-property-name {
        display: none;
    }

    .sw-property-val {
        margin-top: $margin_normal;
    }
}

.aw-studyManager-scrollPanel {
    .sw-section {
        margin: 0;
    }
}

.aw-tcmaStudyManager-listContainer {
    .page-bottom{
        margin-bottom: $margin_x_large;
    }
}


