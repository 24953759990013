// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*========================================================================*/
/*===========================MFE Manage Work Package  ==========================*/
/*========================================================================*/

.aw-mfe-manageWpXrtContainer {
    background-color: $PL_Black_26;
}

.aw-mfe-manageWpTileReVRule {
    color: $PL_Black_16;
}

.aw-ep-managePageColumn {
    border-left-color: $PL_Black_24;
}

.aw-ep-managePageColumnTitle {
    color: $PL_Black_8;
}

.aw-ep-managePageColumnContainer {
    background-color: $PL_Black_26;
}

.aw-mfe-tileSeparator.aw-theme-separator {
    border-color: $PL_Black_2;
}

.aw-mfe-contentPanel.aw-mfe-epProductVariantsTilesViews{
    border-color: $PL_Black_23;
    background-color: $PL_Black_26;
}

.aw-mfe-contentPanelContainer .aw-mfe-contentPanel.aw-mfe-epProductVariantsTilesViews{
	border-color: $PL_Black_23;
}

.aw-epSearchObject-box{
    border-color: $PL_Black_24;
}
