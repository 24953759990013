/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2021.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-tcmaHostedRoot-layout {

    .awRoot .aw-layout-locationPanel,
    aw-page .aw-layout-locationPanel,
    aw-showobject-page .aw-layout-locationPanel {
        padding-right: 0;
    }

    aw-header,
    aw-showobject-header {
        .aw-layout-header {
            padding: 0;
            height: 5.5em;
        }
    }

    .aw-layout-headerTitleContainer {
        padding: 0;
    }

    .afx-base-parentElement {
        /** !important is required here to override
        'height="7.25f"' inline style set by 'aw-row'
        inside aw-page directive html   */
        @include flex(1 1 auto !important);
    }

    aw-navigate-breadcrumb {
        margin-left:15px;
        height: 20px;
    }
    .aw-layout-breadCrumbs{
        margin-left:15px;
        height: 20px;
    }

    .aw-tcmaGlobalSearch-searchBox{
        padding: 8px 16px 0 16px;

        aw-search-box, .aw-search-searchBox {
            width: inherit;

            .aw-uiwidgets-searchBoxContainer{
                height: 30px;
            }
        }
    }

}
