// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*=========================================================================*/
/*========================== EP Production Program =========================*/
/*=========================================================================*/
.aw-productionProgram-popupContent {
    >.aw-layout-panelContent {
        gap: $margin_normal;
    }
}

.aw-productVariants-generateLink {
    padding: $padding_small $padding_normal;
    border-radius: rem-calc(1);

    &.aw-link {
        font-weight: normal;

        &:hover {
            text-decoration: none;
        }
    }
}

.aw-productVariants-totalRow {
    height: rem-calc(36);
    display: flex;
    align-items: center;
    padding: 0 $padding_normal;
    gap: $margin_small;
    flex-shrink: 0;

    .aw-productVariants-totalRowValue {
        font-weight: 500;
    }
}
