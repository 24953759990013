// // Copyright 2020 Siemens Product Lifecycle Management Software Inc.

// // mixins
// @import 'mixins/mixins';

.aw-clsadmin-classes,
.aw-clsadmin-keylov,
.aw-clsadmin-properties,
.aw-clsadmin-class-attributes {
    padding: $padding_normal 0 $padding_normal 0;
}

.aw-clsadmin-class-attributes {
    display: flex;
}


div.aw-widgets-cellListItem.sw-aria-border div.aw-cls-labelselect div.aw-clspanel-propertyLabelField .sw-component {
    padding : 0;
    margin : 0;
}

.aw-clsadmin-class-properties {
    padding-bottom: $padding_normal;
}

.aw-clsadmin-nodes-properties,
.aw-clsadmin-class-properties,
.aw-clsadmin-prop-properties,
.aw-clsadmin-keylov-properties {
    overflow-y: auto;
}

.aw-clsadmin-nodes,
.aw-clsadmin-classes,
.aw-clsadmin-keylov,
.aw-clsadmin-properties {
    .aw-clspadmin-prop-section {
        margin-bottom: 0;
    }
}

.aw-clsadmin-nodes-properties {
    padding-right: $padding_normal;
}

//import in top line
.aw-clsadmin-import {
    margin: $margin_normal;
}
.aw-clsadmin-import .sw-button{
    width: 100px;
    float: right;
    padding: $padding_xsmall;
}

.aw-clsadmin-column_panel .sw-column {
    width: 100%;
    .aw-base-scrollPanel {
        height: calc(100vh - 580px);
        .aw-widgets-cellListWidget .aw-widgets-cellListItem {
            max-width: rem-calc(420);
        }
    }
}

.aw-clsadmin-column_panel {
    padding-left: $padding_small;
    height: calc(100vh - 480px);
}
.aw-clsadmin-column_panel2 {
    margin-top: -42px;
    height: calc(100vh - 95px);
}

.aw-clsadmin-column_panel {
    .sw-column {
        width: 100%
    }
}

.aw-clsadmin-columnChart {
    height: 250px;
    padding: 0 350px 0 0;
    width: 50%
}

.aw-clsadmin-summary {
    padding-left: $padding_small;
    width: 100%
}

.aw-clsadmin-objects .aw-negative-button  {
    border: none;
    padding: 0;
    margin: 0;
}

.aw-clsadmin-objectNames{
    padding-right: 4px;
    padding-top: 5px;
}

.aw-clsadmin-objectNamesList{
    padding-bottom: 5px;
}

.aw-clsadmin-objects .aw-widgets-chip:not(.disabled):not(.aw-widgets-staticChip) {
    box-shadow: none;
}

.aw-clsadmin-objects .aw-widgets-chip:not(.disabled):not(.aw-widgets-staticChip):hover {
    box-shadow: none;
}

.aw-clsadmin-classTable aw-splm-table,
.aw-clsadmin-classAttrTable aw-splm-table,
.aw-clsadmin-nodeTable aw-splm-table,
.aw-clsadmin-keyLovTable aw-splm-table,
.aw-clsadmin-propertyTable aw-splm-table {
    .aw-splm-tableViewport {
        overflow-x: hidden;
    }
    .aw-splm-tablePinnedRow.ui-grid-row .ui-grid-cell:last-child {
        border: none;
    }
    .aw-splm-tablePinnedRow.ui-grid-row .ui-grid-cell:last-child:focus {
        border: 2px solid;
    }
}

.aw-clsadmin-classes aw-splm-table {
    .aw-splm-tableHeaderCell:last-of-type .aw-splm-tableHeaderCellSplitter {
        display: none;
    }
}

//Adjust sections in SWA
.aw-clsadmin-attributes,
.aw-clsadmin-associations {
    .sw-section.sw-section-content {
        padding-right: 0;
        overflow-x: hidden;
    }
}

.aw-clsadmin-classAttributesTab {
    .sw-section {
        height: inherit;
        .sw-section.sw-section-content {
            height: calc(100% - 10px);
        }
    }
}

.aw-clsadmin-attributes {
    .aw-splm-tableContainer {
        overflow-x: hidden;

        .aw-splm-tableViewport {
            overflow-x: hidden;
        }
    }
}

.aw-clsadmin-classes-properties {
    display: inline-flex;
}

.aw-clsadmin-propertyTable .aw-search-inContentSearchBox,
.aw-clsadmin-keyLovTable .aw-search-inContentSearchBox,
.aw-clsadmin-classTable .aw-search-inContentSearchBox,
.aw-clsadmin-nodeTable .aw-search-inContentSearchBox {
    width: auto;
    padding: 16px 16px 10px 16px;
}

.aw-clsadmin-cellListCellContent {
    float: left;
    width: 100%;
}

.aw-clsadmin-filters .aw-panelSection {
    padding-left: 0;
    padding-right: 0;
}

.aw-clsadmin-importPanel {
    .sw-property.sw-row .sw-property-name {
        width: 0;
        min-width: 0;
    }
}

.aw-clsAdminBreadCrumb-widget div .aw-link-with-popup {
    border: 0;
}

.aw-clsAdminBreadCrumb-container {
    display: flex;
    .aw-layout-breadCrumbs{
        padding-right: $padding_xlarge;
    }
    .sw-property{
        padding-left: $padding_xlarge;
        .sw-property-name {
            margin: 0;
            min-width: rem-calc(48);
        }
    }
}

.aw-clsadmin-classes {
    overflow-y: visible;
    .aw-base-scrollPanel > .sw-column {
        overflow: visible;
    }
}

.aw-clsadmin-propHeight {
    height: rem-calc(16);
}
