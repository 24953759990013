// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*=========================================================================*/
/*============================ EP boe =====================================*/
/*=========================================================================*/

.aw-ep-epAssignWorkAreaTree .aw-jswidgets-grid {
    .aw-splm-tablePinnedContainer .ui-grid-row:last-child {
        border-bottom-width: 0;
    }
    .aw-splm-tableScrollContainer .ui-grid-row:last-child {
        border-bottom-width: 0;
    }
}