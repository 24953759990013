// Copyright 2024 Siemens Product Lifecycle Management Software Inc.

.aw-floatpdf-commands {
    margin: $margin_normal;
    display: flex;
    justify-content: end;
    padding: rem-calc(8);
    margin: 0;
}

.aw-floatpdf-panel {
    border: $command_panel_border;
    width: 100%;
    height: 100%;
}

.aw-floatpdf-panel .guidance-container-fullWidth {
    border: none;
}
