// Copyright 2022 Siemens Product Lifecycle Management Software Inc.

.aw-epBvrGraphics-galleryListContainer {
    box-shadow: 0 4px 4px -4px rgb(0 0 0 / 30%) inset;
    background-color: $brand_secondary_content_background;
    .aw-widgets-cellListItem {
        background-color: $Siemens_Snow;
        box-shadow: $drop_shadow_small;
        border-color: $brand_secondary_border;
        &:hover {
            background-color: $cell_list_item_hover;
        }
    }
}

.aw-epBvrGraphics-thumbnailLabel {
    color: $PL_Black_8;
}
