/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2018.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Schedule Manager Gantt default styling */

.smgantt .selected_link .gantt_line_wrapper,
.selected_link .gantt_link_arrow {
    z-index: 2;
}

.smgantt .gantt_task_line.critical_task.gantt_critical_task.gantt_task_inline_color .gantt_task_progress,
.gantt_critical_task .gantt_task_progress {
    opacity: 1;
}

.smgantt .gantt_task_line.gantt_task_inline_color .gantt_task_progress {
    opacity: 1;
}

.smgantt .task_progress_border .gantt_task_progress
    {
    border-right: 1px solid;
}

.smgantt .gantt_grid_head_cell {
    font-family: $font-family;
    font-size: $fontsize_normal;
    font-weight: 600;
    display: table-cell;
    vertical-align: middle;
    line-height: 150%;
}

.smgantt .gantt_grid_scale {
    height: 60px;
    display: table;
}

.smgantt .gantt_tree_content {
    font-family: $font-family;
    font-size: $fontsize_normal;
    padding-left: 5px
}

.smgantt gridbox_dhx_skyblue hdr td {
    text-align: center;
    vertical-align: middle;
}

.smgantt .gantt_scale_cell {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    height: 250%;
    font-family: $font-family;
    font-size: $fontsize_normal;
    cursor: all-scroll;
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
}

.smgantt .gantt_milestone {
    visibility: hidden;
    border: 0 solid;
    line-height: 15px;
    width: 20px;
    height: 20px;
}

.smgantt .gantt_milestone .gantt_task_content {
    width: 20px;
    height: 20px;
}

.smgantt .gantt_task_scale {
    height: 60px;
}

.smgantt .gantt_task_line .gantt_task_content {
    font-family: $font-family;
    font-size: $fontsize_normal;
}

.smgantt .gantt_task_line {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 3px;
}

.smgantt .gantt_task_line.gantt_milestone {
    border-radius: unset;
    z-index: 2;
}

.smgantt .summary_task,
.smgantt .schedule_task,
.smgantt .proxy_task {
    border: 1px solid;
}

.smgantt .gantt_scale_line .gantt_scale_cell {
    font-family: $font-family;
    font-size: $fontsize_normal;
}

.smgantt .gantt_scale_line:nth-child(n) .gantt_scale_cell {
    font-weight: 600;
}

.smgantt .gantt_scale_line:nth-child(2) .gantt_scale_cell,
.smgantt .gantt_scale_line:nth-child(3) .gantt_scale_cell {
    border-bottom: 1px solid;
}

.smgantt .gantt_tree_icon {
    padding-left: 5px;
    width: 16px;
}

.smgantt .gantt_tooltip {
    border: 1px solid;
    text-transform: capitalize;
    max-width: 250px;
    overflow-x: auto;
    border-radius: 3px;
    padding: 0;
}

.gantt_tooltip .gantt_tooltip_icons,
.gantt_tooltip .gantt_tooltip_text {
    font-family: $font-family;
    font-size: $fontsize_normal;
    text-align: left;
    line-height: 15px;
}

.gantt_side_content,
.smgantt .gantt_resource_assignee {
    font-size: $fontsize_normal;
}

.smgantt.gantt_task_text {
    text-align: justify;
}

.gantt_task_content {
    display: flex;
    justify-content: center;
}

.smgantt .gantt_tooltip .gantt_task_text {
    display: flex;
}

.smgantt .gantt_marker {
    border-style: none;
}

.smgantt .gantt_marker .gantt_marker_content {
    opacity: 1;
}

.smgantt .gantt_marker.today {
    z-index: 1;
}

.smgantt .gantt_link_arrow,
.smgantt .gantt_task_link .gantt_line_wrapper {
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.smgantt .gantt_split_parent, .gantt_split_subproject {
    opacity: 1;
}

.smgantt .gantt_grid_data .task_added_in_whatif_mode .gantt_tree_content,
.smgantt .gantt_grid_data .modified_in_whatif_mode .gantt_tree_content {
    font-weight: bold;
}

.smgantt .gantt_drag_marker {
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.gantt_arrange_columns {
    background-image: url("image/cmdSettings24.svg");
    width: 19px;
    height: 19px;
    float: right;
}

.smgantt .gantt_arrange_columns_wrapper {
    margin-bottom: 2px;
}

.baseline_properties {
    display: inline;
}

.smgantt .baseline_child_properties {
    position: absolute;
    font-size: $fontsize_small;
    display: inline-flex;
    z-index: 1;
}

.smgantt .gantt_dates {
    font-size: $fontsize_small;
}

.gantt_side_content.gantt_left,
.gantt_side_content.gantt_right {
    top: -2.5px;
}

.smgantt .gantt_baseline_comfy {
    position: absolute;
    top: 0;
    border-radius: 0;
    margin-top: -11px;
    height: 12px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: inline-flex;
}

.smgantt .gantt_baseline_compact {
    position: absolute;
    top: 0;
    border-radius: 0;
    margin-top: rem-calc(-16);
    height: 10px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: inline-flex;
}

.smgantt .has_baseline_comfy {
    margin-top: -6px;
}

.smgantt .has_baseline_comfy .gantt_line_wrapper {
    margin-top: -4px;
}

.smgantt .has_baseline_comfy .gantt_link_arrow {
    margin-top: -4px;
}

.smgantt .has_baseline2 .gantt_line_wrapper {
    margin-top: -14px;
}

.smgantt .has_baseline2 .gantt_link_arrow {
    margin-top: -15px;
}

.smgantt .has_baseline_comfy.gantt_milestone .gantt_task_content {
    width: 10px;
    height: 10px;
    margin-left: -1px;
}

.smgantt .has_baseline_compact {
    margin-top: -6px;
}

.smgantt .has_baseline2 {
    margin-top: -14px;
}

.smgantt .has_baseline_compact .gantt_line_wrapper {
    margin-top: -6px;
}

.smgantt .has_baseline_compact .gantt_link_arrow {
    margin-top: -6px;
}

.smgantt .has_baseline_compact.gantt_milestone .gantt_task_content {
    width: 10px;
    height: 10px;
    margin-left: -1px;
}

.smgantt .gantt_base_milestone_comfy {
    position: absolute;
    width: rem-calc(7);
    height: rem-calc(7);
    border-radius: 0;
    margin-left: 1px;
    margin-top: -1px;
    transform: rotate(45deg);
    z-index: 1;
}

.smgantt .gantt_base_milestone_compact {
    position: absolute;
    width: rem-calc(6);
    height: rem-calc(6);
    margin-left: 1px;
    margin-top: -2px;
    border-radius: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.smgantt .gantt_baseline1_milestone {
    position: absolute;
    width: rem-calc(11);
    height: rem-calc(11);
    margin-top: rem-calc(2);
    border-radius: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.smgantt .gantt_baseline2_milestone {
    position: absolute;
    width: rem-calc(11);
    height: rem-calc(11);
    margin-top: 13px;
    border-radius: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.smgantt .gantt_baseline_comfy,
.smgantt .gantt_base_milestone_comfy {
    border: 1px solid;
}

.smgantt .gantt_baseline_compact,
.smgantt .gantt_base_milestone_compact {
    border: 1px solid;
}

.smgantt .gantt_baseline1_milestone,
.smgantt .gantt_baseline2_milestone {
    border: 1px solid;
}

.aw-schedulemanager-ganttBreadCrumb .aw-layout-breadCrumbs div {
    display: inline;
}

.smgantt .gantt_tooltip_deliverables_icon {
    background-image: url("image/cmdAssignDeliverable24.svg");
    background-repeat: no-repeat;
}

.smgantt .gantt_tooltip_members_icon {
    background-image: url("image/cmdChangeOwner24.svg");
    background-repeat: no-repeat;
}

.smgantt .gantt_center_align_text {
    position: absolute;
    right: 0px;
}

.smgantt .gantt_task_date {
    display: inline-flex;
    padding-left: 8px;
}

.smgantt .gantt_task_status {
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 5px 1px 5px 8px;
    background-origin: content-box;
    display: inline-flex;
    width: 16px;
    height: 16px;
}

.smgantt .gantt_tooltip_not_started_task {
    background-image: url("image/indicatorNotStarted16.svg");
}

.smgantt .gantt_tooltip_in_progress_task {
    background-image: url("image/indicatorInProgress16.svg");
}

.smgantt .gantt_tooltip_needs_attention_task {
    background-image: url("image/indicatorWarning16.svg");
}

.smgantt .gantt_tooltip_pending_task {
    background-image: url("image/indicatorPending16.svg");
}

.smgantt .gantt_tooltip_paused_task {
    background-image: url("image/indicatorPaused16.svg");
}

.smgantt .gantt_tooltip_unable_to_complete_task {
    background-image: url("image/indicatorUnableToComplete16.svg");
}

.smgantt .gantt_tooltip_late_task {
    background-image: url("image/indicatorDelayed16.svg");
}

.smgantt .gantt_tooltip_complete_task {
    background-image: url("image/indicatorCompleted16.svg");
}

.smgantt .gantt_tooltip_abandoned_task {
    background-image: url("image/indicatorAbandoned16.svg");
}

.smgantt .gantt_tooltip_aborted_task {
    background-image: url("image/indicatorAborted16.svg");
}

.smgantt .gantt_task_icon_center {
    float: right;
    width: 16px;
    height: 16px;
    margin: 5px 3px 5px 8px;
    position: absolute;
    right: 0;
}

.smgantt .gantt_tooltip_deliverables_icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.smgantt .gantt_tooltip_members_icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.smgantt .gantt_tooltip_resource_assignee {
    padding: 8px 16px 8px 16px;
}

.smgantt .gantt_taskbar_person_icon {
    background-image: url("image/indicatorResourceAssigned16.svg");
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-left: 8px;
    background-origin: content-box;
    display: -webkit-inline-box;
    width: 16px;
    height: 16px;
}

.smgantt .gantt_taskbar {
    text-align: center;
    display: inline-flex;
    position: initial;
}

.smgantt .gantt_task_cell {
    border-right-width: 1px
}

.smgantt .gantt_task_row {
    border-bottom-width: 1px
}

.smgantt .gantt_marker.week_end {
    z-index: 0
}

.smgantt .gantt_task_line {
    z-index: 1;
}

.smgantt .gantt_task_line,
.smgantt .gantt_line_wrapper,
.smgantt .gantt_ver_scroll {
    z-index: 1;
}

.smgantt .gantt_scale_line,
.smgantt .gantt_grid,
.smgantt .gantt_container {
    border-radius: $table_border_radius;
}

.smgantt .gantt_tree_icon.gantt_close {
    background-image: url("image/miscExpandedTree12.svg");
    width: 12px;
    height: 12px;
    vertical-align: baseline;
}

.smgantt .gantt_tree_icon.gantt_open {
    background-image: url("image/miscCollapsedTree12.svg");
    width: 12px;
    height: 12px;
    vertical-align: baseline;
}

.smgantt .gantt_tree_indent {
    vertical-align: middle;
}

.smgantt .gantt_scale_cell.gantt_last_cell {
    border: none
}

.smgantt .gantt_hide_finish_drag .gantt_task_drag.task_right.task_end_date {
    display: none;
}

.smgantt .gantt_hide_start_drag .gantt_task_drag.task_left.task_start_date {
    display: none;
}

.smgantt .summary_task.gantt_hide_progress_drag .gantt_task_progress_drag,
.smgantt .proxy_task.gantt_hide_progress_drag .gantt_task_progress_drag {
    display: none;
}

.smgantt .gantt_grid_data .gantt_row.gantt_selected,
.smgantt .gantt_grid_data .gantt_row.odd.gantt_selected,
.smgantt .gantt_task_row.gantt_selected {
    z-index: 1;
}

.smgantt .event.gantt_selected .gantt_task_content{
    border-width: 2px;
}

.smgantt .gantt_link_control.task_right div.gantt_link_point,
.smgantt .gantt_link_control.task_left div.gantt_link_point {
    z-index: 1;
}

.smgantt .gantt_link_control.task_right div.gantt_link_point {
    margin-left: 0;
}

.smgantt .gantt_link_control.task_left div.gantt_link_point {
    margin-left: 5px;
}

.smgantt .gantt_task_stackedWithoutInfo .gantt_task_stackedCount {
    position: absolute;
    margin-left: 75%;
    margin-top: 35%;
    transform: translate(-130%, -50%);
    width: 10px;
    font-size: 10px;
}

.smgantt .gantt_task_stackedWithInfo .gantt_task_stackedCount
{
    position: absolute;
    top: 22%;
    left: 12.5%;
    transform: translate(-130%, -50%);
    width: 10px;
    font-size: 10px;
}

.smgantt .gantt_task_stackedWithoutInfo {
    vertical-align: top;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: -3px;
    margin-left: -23px;
    text-align: center;
}

.smgantt .gantt_task_stackedWithInfo {
    vertical-align: top;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: -30%;
    text-align: center;
    margin-top: -17%;
}

.smgantt .gantt_task_stackedWithoutInfoCompact {
    vertical-align: top;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-top: -2px;
    margin-left: -19px;
    text-align: center;
}

.smgantt .gantt_task_stackedWithoutInfoCompact .gantt_task_stackedCount {
    position: absolute;
    top: 22%;
    left: 68.5%;
    transform: translate(-130%, -50%);
    width: 10px;
    font-size: 9px;
}

.smgantt .gantt_task_stackedWithInfoCompact {
    vertical-align: top;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-top: -14px;
    margin-left: -21px;
    text-align: center;
}
.smgantt .gantt_task_stackedWithInfoCompact .gantt_task_stackedCount {
    position: absolute;
    top: 32%;
    left: 12.25%;
    transform: translate(-130%, -50%);
    width: 10px;
    font-size: 9px;
}

.smgantt .gantt_task_stackedWithInfoCompactAndFind .gantt_task_stackedCount {
    position: absolute;
    top: 32%;
    left: 10%;
    transform: translate(-130%, -50%);
    width: 10px;
    font-size: 9px;
}

.smgantt .gantt_task_stackedWithInfoCompactAndFind {
    vertical-align: top;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-top: -18px;
    margin-left: -21px;
    text-align: center;
}

.smgantt .gantt_baseline.baseline2 {
    position: absolute;
    border-radius: 0;
    margin-top: -7px;
    height: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: inline-flex;
    border: 1px solid;
}

.smgantt .gantt_baseline.baseline1 {
    position: absolute;
    border-radius: 0;
    margin-top: -10px;
    height: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: inline-flex;
    border: 1px solid;
}

.saw1-border-chartColor1 div.aw-default-cell {
    border-style: solid;
    border-left-width: 5px;
}

.saw1-border-chartColor2 div.aw-default-cell {
    border-style: solid;
    border-left-width: 5px;
}
