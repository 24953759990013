// Copyright 2021 Siemens Product Lifecycle Management Software Inc.

.aw-workinstr-overviewCommandBar {
    .aw-workinstr-hierarchyLayoutOverviewCmd,
    .aw-workinstr-overviewCmd {
        border-color: $brand_secondary_border;
    }

    .aw-workinstr-overviewActiveCmd {
        background-color: $brand_primary_background;
        .aw-workinstr-overviewCmdTxt {
            color: $Siemens_Snow;
        }
        svg {
            path {
                fill: $Siemens_Snow;
            }
        }
    }
}

