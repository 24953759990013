// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@
.aw-epMaturity-configurePlanSearchResultArea {
    border-color: $brand_table_header_background;
}

.aw-epMaturity-effectivitySearchResultArea {
    border-color: $brand_table_header_background;
}

.aw-epMaturity-configurePlanLoadingLabel {
    color: $brand_primary_border;
}
