.t4b-inline-span {
    display: inline-flex !important;
}

.t4b-vertical-scroll {
    overflow-y: scroll !important;
    padding: 4x !important;
    margin-bottom: 0px !important
}

.t4b-info-box {
    max-height: 300px !important;
    width: 500px !important;
}

.t4b-info-box .aw-widgets-propertyContainer > aw-property-label {
    flex: 3 !important;
}