// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-ep-treeTable {
    .aw-jswidgets-grid .aw-splm-tableRow.ui-grid-row {

        &.aw-epDragDrop-resequenceTop {
            border-top-color: $Siemens_Blue_10;
        }

        &.aw-epDragDrop-resequenceBottom {
            border-bottom-color: $Siemens_Blue_10;
        }
    }
}
