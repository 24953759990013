/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2017.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* begin TCOOViewer */

.aw-tcoo-panel
{
    overflow-y: hidden;
}

.aw-tcoo-frame
{
    height: 100vh;
    width: 100%;
}

.aw-selected-users-section .sw-section-content {
    height: calc( 100% - 80px );
}

details.aw-selected-users-section.sw-section {
    max-height: 100%;
}
