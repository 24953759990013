// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@


/*=========================================================================*/
/*============================ EP Change Indications ===========================*/
/*=========================================================================*/

.aw-ep-changeTooltip {
    width: 300px;
}

.aw-ep-ecnInfoIcon {
    width: 2rem;
    margin-right: 0.67rem;
}

.aw-ep-calculatePvTaskbarContainer {
    margin-left: 1rem;
}
